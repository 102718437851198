import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./payment.css";
import * as rdd from "react-device-detect";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

declare global {
  interface Window {
    wpwl: {
      unload?: () => void;
    };
  }
}

export function PaymentDiv(props: { method: string; code: string }) {
  const { t } = useTranslation();
  const [checkout, setCheckout] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [safari, setSafari] = useState(true);
  const user = useSelector(
    (state: RootState) => state.user.user
  );
  useEffect(() => {
    if (!rdd.isSafari) {
      setSafari(false);
    }
  }, []);

  useEffect(() => {
    if(user.phone)
    {setCheckout("");
    if (window.wpwl !== undefined && window.wpwl.unload !== undefined) {
      window.wpwl.unload();
      const scripts = document.querySelectorAll("script");
      scripts.forEach((script) => {
        if (script.src.includes("static.min.js")) {
          script.remove();
        }
      });
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + "payment/checkout/" + props.method,
        {
          user: user,
          code: props.code,
        }
      )
      .then((res) => {
        setCheckout(res.data.id);
        setLoading(false);
      })
      .catch((err) => {
        setCheckout("");
        setLoading(false);
      });}
  }, [props.method, props.code,user]);

  const renderPaymentform = () => {
    const element = document.getElementById("payment-div") as HTMLElement;
    if (element && element.innerHTML) element.innerHTML = "";

    const payment = document.getElementById("payment-div") as HTMLElement;

    const form = document.createElement("form");
    if (props.method === "apple") {
      form.action =
        process.env.REACT_APP_PAYMENT_RESULT_URL_APPLE ||
        "https://www.funny1s.com/result/apple";
      form.setAttribute("data-brands", "APPLEPAY");
    } else if (props.method === "mada") {
      form.action =
        //process.env.REACT_APP_PAYMENT_RESULT_URL_MADA ||
        //"http://localhost:3000/result/mada";
        "https://www.funny1s.com/result/mada";
      form.setAttribute("data-brands", "MADA");
    } else if (props.method === "card") {
      form.action =
        process.env.REACT_APP_PAYMENT_RESULT_URL_CARD ||
        "https://www.funny1s.com/result/card";
      form.setAttribute("data-brands", "VISA MASTER STC_PAY");
    }
    form.setAttribute("class", "paymentWidgets");
    payment.appendChild(form);
  };

  useEffect(() => {
    if (!loading && checkout && checkout !== "") {
      const script = document.createElement("script");
      script.id = "payment-script";
      //script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=${id}`;
      script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkout}`;
      script.async = true;
      const paymentScrpt = document.getElementById(
        "payment-script"
      ) as HTMLScriptElement;
      if (paymentScrpt && paymentScrpt.parentNode)
        paymentScrpt.parentNode.replaceChild(script, paymentScrpt);
      document.body.appendChild(script);
      renderPaymentform();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, checkout]);

  return (
    <>
      <div
        id="payment-div"
        className={`w-full phone:w-[90%] phone:mb-4 ${
          props.method === "apple"
            ? "phone:flex phone:items-start phone:justify-center"
            : ""
        }}`}
      ></div>
      {!safari && props.method === "apple" && (
        <h1 className="web:w-full web:text-center web:font-inter">
          {t("payment.use_safari")}
        </h1>
      )}
    </>
  );
}
