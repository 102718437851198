import { createSlice } from '@reduxjs/toolkit'

export const CycleQuestionSlice = createSlice({
    name: 'cycleQuestion',
    initialState: {
        questionPage: "category",
        showRound: true,
    },
    reducers: {
        setQuestionPage: (state, action) => {
            state.questionPage = action.payload
        },
        SetShowRound: (state, action) => {
            state.showRound = action.payload
        },
    },
})



// Action creators are generated for each case reducer function
export const { setQuestionPage, SetShowRound } = CycleQuestionSlice.actions

export default CycleQuestionSlice.reducer