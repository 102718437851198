import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cover from "../../assets/blog-cover.svg";
import insta from "../../assets/blog-instagram.svg";
import tik from "../../assets/blog-tiktok..svg";
import twit from "../../assets/blog-twitter..svg";
import search from "../../assets/search.svg";
import { Card } from "./Card";
import { Modal } from "./Modal";
import { Blog } from "../../pages/Blog";
import { colors } from "../../utils/constants";
import { useGetBlogByIdMutation, useGetBlogsMutation, useGetTagsMutation } from "../../store/Apis/UserApi";
import "./blog.css";
import { TermsModal } from "../Authentication/TermsModal";
import { useSearchParams } from "react-router-dom";
 
export function BlogDesktop() {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null! as Blog);
  const [selectedTag, setSelectedTag] = useState("");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [blogs, setBlogs] = useState([] as Blog[]);
  const [tags, setTags] = useState([] as string[]);
  const [totalPages, setTotalPages] = useState(1);
  const [getBlogs] = useGetBlogsMutation();
  const [getTags] = useGetTagsMutation();
  const [getBlog] = useGetBlogByIdMutation();
	const [showModal, setShowModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const blog = searchParams.get("blog");

  const updateQueryParam = (data:{}) => {
    setSearchParams(data);
  };

  function loadBlogs(search:string, tags:string[],cPage:number,reset:boolean) {
    setLoading(true)
    getBlogs({page:cPage+1,limit:6,search:search, tags}).unwrap().then((res) => {
      if (res) {
        if(reset)
          setBlogs(res.data)
        else
        setBlogs((prev) => {
          return [...prev, ...res.data]
        });
        setTotalPages(res.totalPages)
        setCurrentPage((prev)=>prev+1)
        setLoading(false)
    }
  }
  ).catch((err) => {
    console.log("blogs",err);
  });
  }

  useEffect(() => {
    if(selected&&selected?._id!==blog)
    {
      updateQueryParam({'blog': selected._id});
    }
      
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if(blog)
    {
      getBlog({id:blog}).unwrap().then((res)=>{
        if(res?.success)
          setSelected(res.data)
      }
      ).catch((err)=>{
        console.log("blog",err)
      })
    }
    getTags(void 0).unwrap().then((res)=>{
      if(res?.success)
        setTags(res.data)
    }).catch((err)=>{
      console.log("tags",err)
    })
   loadBlogs("",[],0,true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  /*const b: Blog = {
    title: "افكار العاب جماعية استعد لأوقات مليئة بالمرح!",
    content: content,
    imgSrc:
      "https://miro.medium.com/v2/resize:fit:720/format:webp/1*-Y_zkzI3teGEI6-Jah_ljQ.jpeg",
  };*/

  return (
    <Suspense>
      <div
        className={`relative z-10 flex flex-col w-full h-full bg-gray-blog phone:hidden web:visible ${
          selected ? "overflow-hidden" : ""
        }`}
      >
        {showModal && <TermsModal params={""} setShowModal={() => setShowModal(false)}></TermsModal>}
        <div className="absolute top-0 w-full flex flex-row justify-between items-center bg-transparent pt-6 pb-4 px-12 backdrop-brightness-90">
          <div className="flex flex-row gap-6">
            <a href="/join"  className="text-white text-xl font-normal hover:underline cursor-pointer">
              {t("herobanner.join")}
            </a>
            <a href="/#how-to" className="text-white text-xl font-normal hover:underline cursor-pointer">
              {t("navbar.how")}
            </a>
            <a href="/#about" className="text-white text-xl font-normal hover:underline cursor-pointer">
              {t("navbar.about")}
            </a>
          </div>
          <h3 className="text-white text-xl font-bold">THE FUNNY1S</h3>
        </div>

        {selected && (
          <Modal
            blog={selected}
            onSelect={setSelected}
            onBack={() => updateQueryParam({})}
            back={t("blog.title") || "العودة"}
          ></Modal>
        )}
        <img
          src={cover}
          className=" w-full"
          alt="family having fun playing"
        ></img>
        <div className="relative w-full h-full bg-transparent">
          <div className="w-full h-full flex flex-row mt-[-300px]">
            <div className="w-1/4 h-full bg-transparent mt-[25%]">
            <div className="w-full flex flex-col pr-[15%] gap-2">
            {
              tags.map((tag,index)=> <span key={index} onClick={()=>{
                setSelectedTag((prev)=>{
                  if(prev && prev===tag)
                  {
                    loadBlogs(searchText,[],0,true)
                    return ""
                  }
                  loadBlogs(searchText,[tag],0,true)
                  return tag
                })
                setCurrentPage(1)
              }} style={{backgroundColor:colors[index%9]}} className={`${selectedTag === tag ? 'dimmed' : 'normal'} w-fit font-bold text-xl px-8 py-2 rounded-md text-center cursor-pointer`}>{tag}</span>)
            }
            </div>
            </div>
            <div className="w-1/2 h-full bg-transparent flex flex-col items-center">
              <h1
                className="text-7xl text-center text-white font-bold font-Inter text-textShadow-lg"
                style={{ textShadow: "8px 2px 2px rgba(0,0,0,0.6)" }}
              >
                {t("blog.title")}
              </h1>
              <div className="relative w-full flex flex-row gap-4 mt-12">
                <input
                  onChange={(event)=>{setSearchText(event.target.value)}}
                  type="text"
                  className="w-[75%] px-8 h-10 bg-white border-2 border-black rounded-md font-Inter shadow-lg placeholder:text-rose-500"
                ></input>
                <img
                  src={search}
                  alt="search"
                  className="absolute right-2 top-3 w-4"
                ></img>
                <button onClick={()=>{
                  setCurrentPage(1);
                  loadBlogs(searchText,[],0,true)
                }} className="w-[25%] h-10 bg-blue-blog p-2 rounded-md border-2 font-Inter font-bold border-white shadow-lg">
                  {t("blog.search")}
                </button>
              </div>
              <div className="w-full grid grid-cols-2 gap-4 mt-8">
                {
                  blogs.length > 0 && blogs.map((blog,index) => (
                    <Card key={index} onSelect={setSelected} blog={blog} />
                  ))
                }
              </div>
              {currentPage<totalPages && !loading && <button onClick={()=>loadBlogs(searchText,[selectedTag],currentPage,false)} className="w-[30%] bg-transparent text-white rounded-md text-xl border-2 font-Inter border-white mt-4 p-1">
                {t("blog.load_more")}
              </button>}
            </div>
            <div className="w-1/4 h-full bg-transparent"></div>
          </div>
        </div>
        <div className="w-full bg-blue-blog-footer p-16 flex flex-col mt-4 items-center">
          <div className="w-full flex flex-row items-center justify-between pb-4">
            <div className="flex flex row gap-4">
              <img className="w-4 cursor-pointer" src={twit} alt="twitter"  onClick={() => { window.open('https://twitter.com/thefunny_ones', '_blank'); }}></img>
              <img className="w-4 cursor-pointer" src={tik} alt="tiktok" onClick={() => { window.open('https://www.tiktok.com/@funnyzedbhx', '_blank'); }}></img>
              <img className="w-4 cursor-pointer" src={insta} alt="instagram" onClick={() => { window.open('https://www.instagram.com/the.funny1s/', '_blank'); }} ></img>
            </div>
            <h2 className="text-white text-md">THE FUNNY1S</h2>
          </div>
          <div
            className="my-8"
            style={{
              width: "100%",
              height: "2px",
              borderBottom: "1px solid rgba(255,255,255)",
            }}
          ></div>
          <div className="w-full flex flex-row items-center justify-between pt-4">
            <h2 className="text-white text-md cursor-pointer hover:underline" onClick={() => setShowModal(true)}>{t('footer.terms')}</h2>
            <h2 className="text-white text-sm font-light" dir="ltr" >© Copyright {new Date().getFullYear()}. All rights reserved</h2>
          </div>
        </div>
      </div>
    </Suspense>
  );
}
