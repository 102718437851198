import { useTranslation } from 'react-i18next';
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Suspense, useEffect, useState } from 'react';
import { motion } from "framer-motion"


export function ProgressBar(props: { playCounter: any, interval: number; handleTimer: any, color: string, textColor: string, type: string, start: boolean }) {
    const { t } = useTranslation();
    const [[h, m, s], setTime] = useState([0, 0, props.interval]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [paused, setPaused] = useState(false);
    const [over, setOver] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [percentage, setPercentage] = useState(100);
    const title = useSelector((state: RootState) => state.mainScreen.progressBarTitle)
    const endQ = useSelector((state: RootState) => state.mainScreen.endTimerQ)
    const endA = useSelector((state: RootState) => state.mainScreen.endTimerA)
    const questionPage = useSelector((state: RootState) => state.cycle.questionPage)

    const tick = () => {
        let stopQ = endQ && (questionPage === "question")
        let stopA = endA && (questionPage === "answer")
        if (paused || over) {
            return;
        }
        if (s === 5 && props.type === "question") {
            props.playCounter()
        }
        if (s === 5 && props.type === "answer") {
            props.playCounter()
        }
        if ((h === 0 && m === 0 && s === 0) || stopQ || stopA) {
            setOver(true);
            if (s === 0)
                setPercentage(0)
            props.handleTimer(s);
        }
        else if (m === 0 && s === 0) {
            setTime([h - 1, 59, 59]);
        } else if (s === 0) {
            setTime([h, m - 1, 59]);
            setPercentage(0)
        } else {
            setTime([h, m, s - 1]);
            setPercentage((100 / props.interval) * s);
        }
    };

    /*const reset = () => {
        setTime([parseInt(0), parseInt(0), parseInt(30)]);
        setPaused(false);
        setOver(false);
    };*/

    useEffect(() => {
        if (props.start) {
            const timerID = setInterval(() => tick(), 1000);
            return () => clearInterval(timerID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [s, over, props.start]);

    return (
        <Suspense>
            <div className='relative h-full w-full'>
                {/*<div
                    style={{ width: `${percentage}%` }}
                    className={`h-full bg-red-600`}>
    </div>*/}

                {props.start && <motion.div
                    initial={{ width: '100%' }} animate={{ width: 0 }} transition={{ duration: props.interval }}
                    className={`h-full ${props.color} shadow-inner z-30`}>
                </motion.div>}
                {!props.start && <div
                    className={`h-full ${props.color} shadow-inner z-30`}>
                </div>}
                <h3 className={`absolute bottom-4 w-screen text-6xl ${props.textColor} text-center z-30`}>{t(title)}</h3>
            </div>
        </Suspense>
    )
}