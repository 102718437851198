import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import hamburger from "../../assets/hamburger.svg";
import { RootState } from "../../store/store";
import { logout } from "../../store/slices/UserSlice";
import { setShowFeedBack } from "../../store/slices/MainScreenSlice";
import Cookies from "js-cookie";
import ar from "../../assets/saudi-arabia.png";
import en from "../../assets/united-kingdom.png";
import { useNavigate } from "react-router-dom";
export function Navbar() {
  const { t, i18n } = useTranslation();
  const [hide, setHide] = useState(true);
  const [hideMenu, setHideMenu] = useState(true);
  const authenticated = useSelector(
    (state: RootState) => state.user.authenticated
  );
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const howToRef = document.getElementById("how-to") as HTMLElement;
  const aboutRef = document.getElementById("about") as HTMLElement;
  const [selectedOption, setSelectedOption] = useState("ar");
  const navigate = useNavigate();


  const lngs = [
    {
      code: "ar",
      nativeName: "العربية",
      logo: ar,
    },
    {
      code: "en",
      nativeName: "English",
      logo: en,
    },
  ];

  useEffect(() => {
    let language = Cookies.get("language");
    if (!language) language = "ar";
    i18n.changeLanguage(language);
    Cookies.set("language", language, {
      secure: false,
      sameSite: "lax",
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: 30,
    });
    setSelectedOption(language);
    if (language !== "ar") document.body.dir = "ltr";
    else document.body.dir = "rtl";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event: any) => {
    Cookies.set("language", event.target.value, {
      secure: false,
      sameSite: "lax",
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: 30,
    });
    setSelectedOption(event.target.value);
    i18n.changeLanguage(event.target.value);
    if (event.target.value !== "ar") document.body.dir = "ltr";
    else document.body.dir = "rtl";
  };

  return (
    <Suspense>
      <nav className="relative phone:w-screen flex items-center justify-between flex-wrap lg:p-6 phone:p-2  ">
        <div className="block lg:hidden flex flex-row w-full items-start justify-between">
          <button
            className="flex items-center p-2 text-teal-200 border-teal-400 hover:text-white hover:border-white"
            onClick={() => {
              console.log("clicked");
              setHideMenu((prev) => {
                return !prev;
              });
            }}
          >
            <img alt="burger menu" src={hamburger}></img>
          </button>
          <div className="flex flex-row items-center">
            <select
              className="p-2 text-2xl font-Inter text-black me-2 border-none hover:underline bg-transparent"
              value={selectedOption}
              onChange={handleChange}
            >
              {lngs.map((lng) => {
                return (
                  <option
                    key={lng.code}
                    className="relative text-black bg-transparent"
                    value={lng.code}
                  >
                    {lng.nativeName}
                  </option>
                );
              })}
            </select>
            {selectedOption === "en" && (
              <img src={en} className="me-1 mb-1 h-6" alt="english logo" />
            )}
            {selectedOption === "ar" && (
              <img src={ar} className="me-1 mb-1 h-6" alt="english logo" />
            )}
          </div>
        </div>
        <div
          className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto phone:hidden`}
        >
          <div className="text-sm lg:flex-grow">
            {!authenticated && (
              <a
                href="/login"
                className="block mt-4 web:pr-16 lg:inline-block font-Inter lg:mt-0 text-2xl text-black hover:text-white hover:underline mr-4"
              >
                {t("login.enter")}
              </a>
            )}
            {authenticated && (
              <div
                className="relative block mt-4 web:pr-16 lg:inline-block font-Inter lg:mt-0 text-2xl text-black hover:text-white hover:underline mr-4 cursor-pointer"
                onClick={() => setHide(!hide)}
              >
                {" "}
                <span>
                  {user?.name} {user?.familyName}
                </span>
                {!hide && (
                  <div className="absolute flex flex-col w-[82%] bg-black">
                    <h3
                      className="px-2 pt-2 font-Inter text-orange text-xl border-b border-gray-500 hover:underline cursor-pointer"
                      onClick={() => dispatch(setShowFeedBack(true))}
                    >
                      {t("navbar.share")}
                    </h3>
                    <h3
                      className="px-2 pb-2 font-Inter text-orange text-xl hover:underline cursor-pointer "
                      onClick={() => {
                        dispatch(logout({}));
                      }}
                    >
                      {t("navbar.logout")}
                    </h3>
                  </div>
                )}
              </div>
            )}
            <a
              href="#how-to"
              className="block mt-4 lg:inline-block web:px-8 lg:mt-0 text-2xl font-Inter text-black hover:text-white hover:underline mr-4"
            >
              {t("navbar.how")}
            </a>
            <a
              href="/blog"
              className="block mt-4 lg:inline-block web:px-8 lg:mt-0 text-2xl font-Inter text-black hover:text-white hover:underline mr-4"
            >
              {t("navbar.blog")}
            </a>
            <a
              href="#about"
              className="block mt-4 lg:inline-block web:px-8 lg:mt-0 text-2xl font-Inter text-black hover:text-white hover:underline mr-4"
            >
              {t("navbar.about")}
            </a>
            <select
              className="block mt-4 lg:inline-block web:px-8 lg:mt-0 text-2xl font-Inter text-black border-none hover:underline ms-4 bg-transparent"
              value={selectedOption}
              onChange={handleChange}
            >
              {lngs.map((lng) => {
                return (
                  <option
                    key={lng.code}
                    className="text-black bg-transparent"
                    value={lng.code}
                  >
                    {lng.nativeName}
                  </option>
                );
              })}
            </select>
            {selectedOption === "en" && (
              <img
                src={en}
                className="block lg:inline-block ms-1 mb-2 h-6"
                alt="english logo"
              />
            )}
            {selectedOption === "ar" && (
              <img
                src={ar}
                className="block lg:inline-block ms-1 mb-2 h-6"
                alt="english logo"
              />
            )}
          </div>
        </div>

        <div
          className={`absolute w-[85%] h-64 bg-navBg top-12 z-30 grow flex flex-col items-start justify-between ms-3 pb-12 ps-4 lg:hidden ${hideMenu ? "hidden" : ""
            }`}
        >
          {!authenticated && (
            <a
              href="/login"
              className="block mt-4 lg:inline-block web:px-8 lg:mt-0 text-2xl font-Inter text-white hover:text-white hover:underline "
            >
              {t("login.enter")}
            </a>
          )}
          {authenticated && (
            <span className="block mt-4 web:pr-16 lg:inline-block font-Inter lg:mt-0 text-2xl text-white hover:text-white hover:underline">
              {user?.name} {user?.familyName}
            </span>
          )}
          {authenticated && (
            <span
              className="block mt-4 web:pr-16 lg:inline-block font-Inter lg:mt-0 text-2xl text-white hover:text-white hover:underline"
              onClick={() => dispatch(setShowFeedBack(true))}
            >
              {t("navbar.share")}
            </span>
          )}

          <span
            onClick={() => {
              if (howToRef) howToRef.scrollIntoView({ behavior: "smooth" });
              setHideMenu(true);
            }}
            className="block mt-4 lg:inline-block web:px-8 lg:mt-0 text-2xl font-Inter text-white hover:text-white hover:underline "
          >
            {t("navbar.how")}
          </span>
          <span
            onClick={() => {
              navigate('/blog')
            }}
            className="block mt-4 lg:inline-block web:px-8 lg:mt-0 text-2xl font-Inter text-white hover:text-white hover:underline "
          >
            {t("navbar.blog")}
          </span>
          <span
            onClick={() => {
              if (aboutRef) aboutRef.scrollIntoView({ behavior: "smooth" });
              setHideMenu(true);
            }}
            className="block mt-4 lg:inline-block web:px-8 lg:mt-0 text-2xl font-Inter text-white hover:text-white hover:underline "
          >
            {t("navbar.about")}
          </span>

          {authenticated && (
            <span
              className="block mt-4 web:pr-16 lg:inline-block font-Inter lg:mt-0 text-2xl text-white hover:text-white hover:underline"
              onClick={() => {
                dispatch(logout({}));
                setHideMenu(true);
              }}
            >
              {t("navbar.logout")}
            </span>
          )}
        </div>
      </nav>
    </Suspense>
  );
}
