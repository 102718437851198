import { createSlice } from '@reduxjs/toolkit'
import { playersVariants } from '../../utils/constants';
import { getRandomElements, shuffle } from '../../utils/utilties'

export const MainScreenSlice = createSlice({
    name: 'mainScreen',
    initialState: {
        players: new Array<IPlayer>(),
        questions: [{
            "category": "جزيرة الرجال ",
            "question": "علم جزيرة الرجال يتكون من ثلاث ....  ",
            "answer": "ارجل",
            "number": 20,
            "suggestions": [
                "خشوم",
                "تنانين",
                "موزات",
                "شموس",
                "خيارات",
                "سيوف",
                "كور",
                "مراوح",
                "كعوب",
                "مغاطات",
                "قيتارات",
                "سيارات",
                "مداخن",
                "كبوتات",
                "زببة"
            ],
            "answerSFX": "",
            "questionSFX": ""
        },
        {
            "category": "ميرلاند",
            "question": "رياضة ميرلاند الرسمية هي ....",
            "answer": "مبارزة الفرسان",
            "number": 21,
            "suggestions": [
                "كورة",
                "بولينق",
                "تنس",
                "رمي اسهم",
                "دارت",
                "طائرة",
                "كركت",
                "طيران شراعي",
                "كورة اليد",
                "رمي",
                "قنص",
                "مشي",
                "الركض",
                "مصارعة الطين",
                "الشطرنج"
            ],
            "answerSFX": "",
            "questionSFX": ""
        },
        {
            "category": "ايكوادور ",
            "question": "في عام ١٩٦٧ بلدة صغيرة في الاكوادور قررت تنتخب منتج يمثل البلدة! و المنتج المنتخب كان ....",
            "answer": "بودرة رجول",
            "number": 22,
            "suggestions": [
                "شبس",
                "بيرة",
                "معجون اسنان",
                "كورة بولينق",
                "شراب",
                "لمبة",
                "مراية",
                "اله كتابة",
                "مفك",
                "بيت",
                "حصات",
                "بيانو",
                "فستق",
                "زبالة",
                "تمثال",
                "رسمة"
            ],
            "answerSFX": "",
            "questionSFX": ""
        },
        {
            "category": "نيوهامشر",
            "question": "مجموعة معروفة بروبين هود في نيوهامشر و كل الي يسوونة انهم يدفعون عن الناس .....",
            "answer": "عداد المواقف",
            "number": 23,
            "suggestions": [
                "عملياتهم",
                "قهوتهم",
                "سندوشاتهم",
                "ملابسهم",
                "مغاسلهم",
                "تذكرة افلامهم",
                "تذكرة باصاتهم",
                "نبيذهم",
                "اثاثهم",
                "تكاسيهم",
                "اجارهم",
                "اتعاب المحكمة",
                "دين بطايق البنك",
                "قروضهم",
                "اكل كلابهم",
                "ادويتهم",
                "حفاضاتهم",
                "تكاليف زواجهم"
            ],
            "answerSFX": "",
            "questionSFX": ""
        },
        {
            "category": "السويد",
            "question": "كطريقة للتظاهر على شرطة مدينة بيلاروس قررو المتظاهرين يطيحون مئات من .... من الطيارة على الشرطة",
            "answer": "دباديب",
            "number": 24,
            "suggestions": [
                "كوكيز",
                "اسلحة",
                "بطاقات معايدة",
                "مخدات",
                "قصايد",
                "ورد",
                "ديناميت",
                "اقنعة خنازير",
                "كبوتات",
                "دجاج",
                "اثاث ايكيا",
                "علب عطور",
                "طحين",
                "كتب",
                "سكاكين",
                "قنابل",
                "روج"
            ],
            "answerSFX": "",
            "questionSFX": ""
        },
        {
            "category": "القيادة تحت التأثير",
            "question": "رجل في مدينة بينسيلفينيا جته مخالفة القيادة تحت التآثير عشانة فاتح علبة بيرة وهو راكب .....",
            "answer": "جزازة عشب منزلية",
            "number": 25,
            "suggestions": [
                "سيكل بثلاث كفرات",
                "خنزير",
                "سيارة قولف",
                "عربية السوبر ماركت",
                "ماعز",
                "لوتزلج",
                "بقرة",
                "سكوتر",
                "جمل",
                "كلب كبير",
                "منضاد",
                "طيارة",
                "دركتر"
            ],
            "answerSFX": "",
            "questionSFX": ""
        },
        {
            "category": "الخوف",
            "question": "اناتيدافوبيا هو الخوف انه في مكان ما في العالم قاعد تناظرك ..... ",
            "answer": "بطة",
            "number": 26,
            "suggestions": [
                "ملكة",
                "مهرجة",
                "طرارة",
                "سمكة",
                "بياعة",
                "مندوبة",
                "جنية",
                "مصارعة سومو",
                "جاسوسة",
                "شيف",
                "خادمة",
                "دب",
                "قسيسة",
                "مدربة نادي",
                "بستانية"
            ],
            "answerSFX": "",
            "questionSFX": ""
        }],
        socket: "",
        currentPage: "home",
        currentQuestion: {
            "category": "الخوف",
            "question": "اناتيدافوبيا هو الخوف انه في مكان ما في العالم قاعد تناظرك ..... ",
            "answer": "بطة",
            "number": 26,
            "suggestions": [
                "ملكة",
                "مهرجة",
                "طرارة",
                "سمكة",
                "بياعة",
                "مندوبة",
                "جنية",
                "مصارعة سومو",
                "جاسوسة",
                "شيف",
                "خادمة",
                "دب",
                "قسيسة",
                "مدربة نادي",
                "بستانية"
            ],
            "answerSFX": "",
            "questionSFX": ""
        },
        suggestions: new Array<ILie>(),
        suggestionsToSend: ["ملكة",
            "مهرجة",
            "طرارة",
            "سمكة",
            "بياعة",
            "مندوبة",
            "خادمة",
            "خادمة",
            "دب",
            "قسيسة"],
        responses: new Array<IResponse>(),
        results: new Array<IResults>(),
        roomCode: "",
        winner: "Abdullah",
        resultPage: 0,
        roundNumber: 0,
        firstRound: 3,
        questionNumber: 0,
        progressBarTitle: "",
        endTimerQ: false,
        endTimerA: false,
        showFeedBack: false,
        ressources: { gameSFX: { "afa.mp3": "", "all_players_answered.mp3": "", "category_music.mp3": "", "choose_answer_music.mp3": "", "credtt_music.mp3": "", "dj-disc-stop.mp3": "", "dont_push.mp3": "", "final_question_music.mp3": "", "final_round.mp3": "", "game_instructions.mp3": "", "intro.mp3": "", "Logo_intro.mp3": "", "lounge.mp3": "", "no_bullying_please.mp3": "", "no_one_chooses_the_truth.mp3": "", "okay_okay.mp3": "", "Pickup_something!!!.mp3": "", "players_on_fire.mp3": "", "quastions_round_1_and_2.mp3": "", "results_music.mp3": "", "round1_&_2.mp3": "", "table_for_2.mp3": "", "table_for_3.mp3": "", "table_for_4.mp3": "", "table_for_5.mp3": "", "table_for_6.mp3": "", "table_for_7.mp3": "", "table_for_8.mp3": "", "table_for_9.mp3": "", "timesup_countdown.mp3": "", "truth_sound_FX.mp3": "", "waiting_for_answers.mp3": "", "winner.mp3": "", "wrong_answer.mp3": "" }, playersSFX: {},pdf:{} },
    },
    reducers: {
        saveRess: (state, action) => {
            state.ressources = action.payload;
        },

        setSocket: (state, action) => {
            state.socket = action.payload
        },
        reset: (state, action) => {
            state.resultPage = 0
            state.firstRound = 3
            state.questionNumber = 0
            state.players.forEach(p => p.score = 0)
        },
        setRoomCode: (state, action) => {
            state.roomCode = action.payload
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        setProgressBarTitle: (state, action) => {
            state.progressBarTitle = action.payload
        },
        addPlayer: (state, action) => {
            let variantIndex = state.players.length;
            let player: IPlayer = { ...action.payload, score: 0, ...playersVariants[variantIndex] }
            let found = state.players.findIndex(element => { return (element.name === action.payload.name) })
            if (found < 0)
                state.players = [...state.players, player]
        },
        addPlayers: (state, action) => {
            action.payload.forEach((p:any)=>{
                let variantIndex = state.players.length;
                let player: IPlayer = { ...p, score: 0, ...playersVariants[variantIndex] }
                let found = state.players.findIndex(element => { return (element.name === p.name) })
                if (player.name && found < 0)
                    state.players = [...state.players, player]
            })   
        },
        removePlayer: (state, action) => {
            let found = state.players.findIndex(element => { return (element.name === action.payload.name) })
            state.players.splice(found, 1)
        },
        setQuestions: (state, action) => {
            state.questions = action.payload
        },
        setRoundNumber: (state, action) => {
            state.roundNumber = action.payload
        },
        setShowFeedBack: (state, action) => {
            state.showFeedBack = action.payload
        },
        setFirstRound: (state, action) => {
            state.firstRound = action.payload
        },
        setSuggestions: (state, action) => {
            state.suggestions = action.payload
            state.suggestionsToSend = action.payload
        },
        addSuggestion: (state, action) => {
            let foundLie = state.suggestions.findIndex(element => { return ((element.name !== action.payload.name) && (element.lie === action.payload.lie)) })
            let lie = action.payload.lie
            let payload = action.payload
            if (foundLie >= 0) {
                lie = state.currentQuestion.suggestions.pop();
                payload.lie = lie
            }
            /*let found = state.suggestions.findIndex(element => { return (element.name === action.payload.name) })
            console.log("found",found)
            if (found >= 0) {
                console.log("found",state.suggestions[found],state.suggestionsToSend[found])
                state.suggestions[found] = payload
                state.suggestionsToSend[found] = lie
            }*/
           
            state.suggestions = [...state.suggestions, payload]
            state.suggestionsToSend = [...state.suggestionsToSend, lie]
            if (state.suggestions.length === state.players.length)
                state.endTimerQ = true
        },
        setSuggestionPoints: (state, action) => {
            if (action.payload.truth) {
                action.payload.players.forEach((playerName: string | undefined) => {
                    let found = state.players.findIndex(element => { return (element.name === playerName) })
                    state.players[found].score = state.players[found].score + action.payload.points
                })
                let suggFound = state.suggestions.findIndex(element => { return (element.lie === action.payload.lie) })
                state.suggestions[suggFound].showCup = true
            }
            else {
                if (!action.payload.our) {
                    let playerIndex = state.players.findIndex(element => { return (element.name === action.payload.name) })
                    state.players[playerIndex].score = state.players[playerIndex].score + action.payload.points
                    let found = state.suggestions.findIndex(element => { return (element.lie === action.payload.lie) })
                    state.suggestions[found].points = action.payload.points
                    state.suggestions[found].showCup = true
                    //uncomment this if you want to deduct points when you choose player lie  
                    /*action.payload.players.forEach((player: string) => {
                        let playerIndex = state.players.findIndex(element => { return (element.name === player) })
                        state.players[playerIndex].score = state.players[playerIndex].score - 500
                        if (state.players[playerIndex].score < 0)
                            state.players[playerIndex].score = 0
                    })*/
                }
                else {
                    action.payload.players.forEach((player: string) => {
                        let playerIndex = state.players.findIndex(element => { return (element.name === player) })
                        state.players[playerIndex].score = state.players[playerIndex].score - 500
                        if (state.players[playerIndex].score < 0)
                            state.players[playerIndex].score = 0
                    })
                }
            }
            state.players.sort((a, b) => b.score - a.score)
        },
        setResponses: (state, action) => {
            state.responses = action.payload
        },
        getResults: (state, action) => {
            let results = new Array<IResults>()
            state.responses.forEach(response => {
                let lie = state.suggestions.find(element => element.lie === response.response)
                let found = results.findIndex(element => { return (element.response.lie === response.response) })
                if (found >= 0) {
                    results[found].players?.push(response.name)
                }
                else {
                    results.push({ players: [response.name], response: lie || {} })
                }
            })
						if(!results.some(r => r.response.truth === true))
						results.push({players: [], response: {lie:state.currentQuestion.answer,truth:true,our:true} })
            results.sort((a, b) => Number(a.response.truth) - Number(b.response.truth))
            state.results = results
        },
        addResponse: (state, action) => {
            let found = state.responses.findIndex(element => { return (element.name === action.payload.name) })
            if (found >= 0)
                state.responses[found] = action.payload
            else {
                state.responses.push(action.payload)
            }
            if (state.responses.length === state.players.length)
                state.endTimerA = true

        },
        setQuestionNumber: (state, action) => {
            state.questionNumber = action.payload
            state.currentQuestion = state.questions[action.payload]
            state.suggestions = new Array<ILie>()
            state.suggestionsToSend = []
            state.responses = new Array<IResponse>()
            state.results = new Array<IResults>()
            state.endTimerQ = false
            state.endTimerA = false
        },
        shuffleSuggestions: (state, action) => {
            let diff = state.players.length - state.suggestionsToSend.length
            if(state.players.length === 2)
            diff = diff+1;
            if (diff > 0) {
                let arr = getRandomElements(state.currentQuestion.suggestions, diff)
                arr.forEach(element => {
                    state.suggestionsToSend.push(element)
                    state.suggestions.push({ lie: element, name: "", our: true, truth: false })
                });
            }
            state.suggestionsToSend.push(state.currentQuestion.answer)
            state.suggestions.push({ lie: state.currentQuestion.answer, name: "", our: true, truth: true })
            state.suggestionsToSend = shuffle(state.suggestionsToSend)
        },

    },
})

export interface IPlayer {
    name: string
    id?: string
    vip?: boolean
    roomId?: string
    score: number
    color: string
    voiceOver: string
}

export interface ILie {
    lie?: string
    name?: string
    our?: boolean
    truth?: boolean
    showCup?: boolean
    points?: number
}
export interface IResults {
    response: ILie
    players?: Array<string>
}
export interface IResponse {
    response: string
    name: string
    roomId: string
}

// Action creators are generated for each case reducer function
export const { setShowFeedBack, saveRess, setSuggestions, addSuggestion, shuffleSuggestions, reset, setResponses, addResponse, setSocket, addPlayer, removePlayer, setRoomCode, setCurrentPage, setProgressBarTitle, setQuestions, setRoundNumber, setFirstRound, setQuestionNumber, getResults, setSuggestionPoints, addPlayers } = MainScreenSlice.actions

export default MainScreenSlice.reducer