
import React, { useState, Suspense, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next";
import instagram from "../../assets/instagram.svg"
import tiktok from "../../assets/Tiktok.svg"
import twitter from "../../assets/twitter.svg"
import arrow from "../../assets/arrow.svg"
import bigMic from "../../assets/bigMic.svg"
import vat from "../../assets/VAT_SAUDI.webp"
import sbc from "../../assets/SBC_logo.png"
import qr from "../../assets/qr.png"
import './footer.css';
import { TermsModal } from "../Authentication/TermsModal";
import Cookies from 'js-cookie';
import { SocketContext } from "../../context/socket";

export function Footer() {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false)
	const [termsAgreed, setTermsAgreed] = useState(false);
	const [email, setEmail] = useState("");
	const socket = useContext(SocketContext)
	const language = Cookies.get("language") ? Cookies.get("language") : "ar";

	useEffect(() => {
		if (socket) {
			socket.off("SUBSCRIBED");
			socket.on("SUBSCRIBED", (data) => {
				setEmail("");
				setTermsAgreed(false);
			})

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socket]);

	const handleTermsChange = (event: any) => {
		setTermsAgreed(event.target.checked);
	};

	const handleEmailChange = (event: any) => {
		setEmail(event.target.value);
	};

	const subscribe = () => {
		if (email && termsAgreed) {
			socket.emit("SUBSCRIBE", { email: email })
		}
	}

	return (
		<Suspense>
			<div>
				{showModal && <TermsModal params={""} setShowModal={() => setShowModal(false)}></TermsModal>}
				<div className="flex flex-row  w-full bg-red phone:hidden web:visible ">
					<div className="flex flex-col w-[50%] h-[100%] items-end pe-24 pt-16" >
						<h3 className="text-5xl w-[82%] font-Inter text-landing-yellow pb-8">{t('footer.subscribe')}</h3>
						<div className="flex w-[80%] flex-col">
							<div className="relative w-[100%] h-16 shadow-xl shadow-slate-800 bg-landing-yellow">
								<input className="w-[90%] h-[100%] bg-landing-yellow ps-2" type="email" placeholder={t('footer.placeholder') || "email"} value={email} onChange={handleEmailChange}></input>
								<img alt="arrow" className={`absolute end-2 bottom-4 h-8 ${language === "ar" ? "" : "rotate-180"} `} src={arrow} onClick={() => { subscribe() }}></img>
							</div>
							<div className="flex flex-row pt-6 justify-items-start w-full">
								<input type="checkbox" name="terms" checked={termsAgreed} onChange={handleTermsChange}></input>
								<h3 className=" text-2xl font-Inter text-landing-yellow ms-4">{t('footer.accept_terms_1')}</h3>
								<h3 className=" text-2xl font-Inter text-landing-yellow ms-2 underline hover:cursor-pointer" onClick={() => setShowModal(true)}>{t('footer.accept_terms_2')}</h3>
							</div>
						</div>
						<h3 className="text-xl w-[82%] mt-10 font-Inter font-thin text-landing-yellow underline cursor-pointer" onClick={() => setShowModal(true)}>{t('footer.terms')}</h3>
						<div className="w-full h-16 flex flex-row justify-end px-8 mt-8">
							<img className="h-16" alt="vat" src={vat} />
							<img className="h-16" alt="sbc logo" src={sbc} />
							<img className="h-16" alt="qr sbc" src={qr} />
						</div>
						<div className="w-full h-16 flex flex-row justify-end gap-8 px-8 pt-2">
							<h3 className="text-xs font-Inter text-landing-yellow ">الرقم الضريبي:311451087100003</h3>
							<h3 className="text-xs font-Inter text-landing-yellow ">رقم التوثيق: 0000019557</h3>
						</div>
					</div>
					<div className="flex flex-col w-[15%] h-[100%] justify-between items-start py-8  " >
						<h3 className="text-3xl font-Inter text-landing-yellow ">{t('footer.follow')}</h3>
						<div className="flex flex-col h-[50%] pt-16">
							<img alt="twitter" className="h-12 p-2 cursor-pointer" src={twitter} onClick={() => { window.open('https://twitter.com/thefunny_ones', '_blank'); }}></img>
							<img alt="instagram" className="h-12 p-2 cursor-pointer" src={instagram} onClick={() => { window.open('https://www.instagram.com/the.funny1s/', '_blank'); }}></img>
							<img alt="tiktok" className="h-12 p-2 cursor-pointer" src={tiktok} onClick={() => { window.open('https://www.tiktok.com/@funnyzedbhx', '_blank'); }}></img>
						</div>
						<h3 className=" text-xl font-Inter text-landing-yellow pt-20">{t('footer.mail')}</h3>
					</div>
					<div className="w-[35%] h-[100%] pt-8 ps-2 " >
						<img alt="mic" className={` ${language === "ar" ? "" : 'transform -scale-x-100'}`} src={bigMic}></img>
					</div>
				</div>

				<div className="flex flex-col w-full bg-red phone:visible web:hidden items-center ">
					<img alt="big mic" className={`w-[30%] mt-4 ${language === "ar" ? "" : 'transform -scale-x-100'}`} src={bigMic}></img>
					<h3 className="text-4xl text-center w-[90%] font-Inter text-landing-yellow mt-6 ">{t('footer.subscribe')}</h3>
					<input className="w-[90%] h-14 placeholder:text-red placeholder:text-xl bg-landing-yellow ps-2 mt-4  shadow-lg shadow-black" type="email" placeholder={t('footer.placeholder') || "email"} value={email} onChange={handleEmailChange}></input>
					<div className="flex flex-row w-[90%] pt-4">
						<input type="checkbox" name="terms" checked={termsAgreed} onChange={handleTermsChange}></input>
						<h3 className="text-xl  font-Inter text-landing-yellow ms-2 ">{t('footer.accept_terms_1')}</h3>
						<h3 className="text-xl  font-Inter text-landing-yellow ms-2 underline hover:cursor-pointer" onClick={() => setShowModal(true)}>{t('footer.accept_terms_2')}</h3>
					</div>
					<button className="shrink mt-8 w-[90%] p-4 text-xl bg-orange shadow-md shadow-black border-2 border-black" onClick={() => { subscribe() }} > {t("footer.send")}</button>
					<h3 className="text-4xl font-Inter text-landing-yellow mt-16 font-bold">{t('footer.follow')}</h3>
					<div className="flex flex-col h-[20%]">
						<img alt="twitter" className="h-16 p-2" src={twitter} onClick={() => { window.open('https://twitter.com/thefunny_ones', '_blank'); }}></img>
						<img alt="instagram" className="h-16 p-2" src={instagram} onClick={() => { window.open('https://www.instagram.com/the.funny1s/', '_blank'); }}></img>
						<img alt="tiktok" className="h-16 p-2" src={tiktok} onClick={() => { window.open('https://www.tiktok.com/@funnyzedbhx', '_blank'); }}></img>
					</div>
					<h3 className=" text-xl font-Inter text-landing-yellow mt-8">{t('footer.mail')}</h3>
					<h3 className="text-xl w-[100%] font-Inter font-thin text-landing-yellow text-center mt-8 mb-6 underline cursor-pointer" onClick={() => setShowModal(true)}>{t('footer.terms')}</h3>
					<div className="w-full flex flex-row justify-center gap-2 pb-2">
						<img className="h-10" alt="vat mobile" src={vat} />
						<img className="h-10" alt="sbc logo mobile" src={sbc} />
						<img className="h-10" alt="sbc qr mobile" src={qr} />
					</div>
					<div className="w-full flex flex-row justify-center gap-4">
						<h3 className="text-xs font-Inter text-landing-yellow ">الرقم الضريبي:311451087100003</h3>
						<h3 className="text-xs font-Inter text-landing-yellow ">رقم التوثيق: 0000019557</h3>
					</div>
				</div>
			</div>
		</Suspense>
	)
}