import React from "react";
import bak from "../../assets/blog-back.svg";
import { Blog } from "../../pages/Blog";
import parse from "html-react-parser";
import "./modal.css";

export function Modal(props: {
  blog: Blog;
  back: string;
  onBack: () => void;
  onSelect: React.Dispatch<React.SetStateAction<Blog>>;
}) {
  return (
    <div className="absolute top-0 w-full h-full z-50 bg-transparent backdrop-blur-sm flex items-center justify-center">
      <div className="relative h-[80%] phone:h-[85%] w-[70%] phone:w-[90%] h-full flex flex-col items-center justify-start bg-white rounded-xl pb-2">
        <div
          className="absolute top-[-25px] w-full flex flex-row items-center justify-start cursor-pointer"
          onClick={() => {
            props.onBack();
            props.onSelect(null!);}}
        >
          <img src={bak} alt="back" className="w-4 h-4"></img>
          <h5 className="text-white font-Inter">{props.back}</h5>
        </div>
        <img
          src={props.blog.imgSrc}
          alt="blog cover"
          className="w-full h-[60%] rounded-t-xl"
        ></img>
        <h1 className="text-4xl phone:text-2xl text-center text-black font-bold font-Inter p-4">
          {props.blog.title}
        </h1>
        <div className="w-full h-full flex flex-col overflow-y-scroll pb-4 px-2">
          {parse(props.blog.content)}
        </div>
      </div>
    </div>
  );
}
