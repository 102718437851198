import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../../store/slices/MainScreenSlice';
import eigtheen from '../../assets/18+.svg';
import logo from '../../assets/logo.svg';
import logoF from '../../assets/funnys-logo-png.png';
import complement from '../../assets/complement.svg';
import { PlayerContext } from '../../context/howler';
import { motion } from 'framer-motion';
import { HowlerPlayer } from '../../context/HowlerPlayer';

export function Logos() {
    const [page, setPage] = useState(0)
    const dispatch = useDispatch()
    const howler = useContext(PlayerContext)


    useEffect(() => {
        /*const introSfx = new howler.Howl({
            src: [ressources['gameSFX']['Logo_intro.mp3']]
        });
        const loungeSfx = new howler.Howl({
            src: [ressources['gameSFX']['lounge.mp3']], loop: true
        });
        var currentTrack = 0;
        let tracks = [introSfx, loungeSfx];
        tracks[currentTrack].on('end', function () {
            console.log("here", currentTrack)
            if (currentTrack === 0)
                currentTrack = 1
        });*/
        setTimeout(() => {
            setPage(1)
        }, 2000);
        setTimeout(() => {
            setPage(2)
        }, 5000);
        setTimeout(() => {
            setPage(3)
            howler.playGameTrack('Logo_intro.mp3', false, HowlerPlayer.musicVolume)
            //dispatch(setCurrentTrack({ track: ressources['gameSFX']['Logo_intro.mp3'], repeat: false }))
        }, 10000);
        setTimeout(() => {
            setPage(4)
        }, 15000);
        setTimeout(() => {
            dispatch(setCurrentPage("lobby"))
            howler.playGameTrack('lounge.mp3', true, HowlerPlayer.musicVolume)
            //dispatch(setCurrentTrack({ track: ressources['gameSFX']['lounge.mp3'], repeat: true }))
        }, 20000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='w-screen h-screen'>
            {page === 0 && <div className='w-screen h-screen bg-dark-blue'></div>}
            {page === 1 && <div className='relative w-screen h-screen bg-dark-blue'>
                <img alt='18+' className='absolute w-full h-full object-contain' src={eigtheen} ></img>
            </div>}
            {page === 2 && <div className='flex flex-col w-screen h-screen bg-dark-blue items-center justify-center'>
                <img alt='logo' className=' w-[50%] h-[50%] object-contain' src={complement} ></img>
            </div>}
            {/*page === 3 && <div className='w-full h-full bg-funnys-logo bg-contain bg-no-repeat'></div>*/
                page === 3 && <div className='flex flex-col items-center justify-center w-screen h-screen bg-beige'>
                    <motion.img animate={{ opacity: 1, rotate: 0 }} initial={{ opacity: 0.1, rotate: 60 }} transition={{ duration: 3 }} alt='18+' className=' w-[40%] h-[40%] object-contain' src={logoF} ></motion.img>
                </div>
            }

            {page === 4 && <div className='w-screen h-screen bg-orange'>
                <motion.img animate={{ scale: 1 }} initial={{ scale: 0.3 }} transition={{ type: 'tween', duration: 1.5 }} alt='logo' className='w-screen h-screen' src={logo} ></motion.img>

            </div>}
        </div>
    )
}