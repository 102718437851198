import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../context/socket";
import { useDispatch } from "react-redux";
import { setLastAnswer } from "../../store/slices/PlayerSlice";

export function Lie() {
  const { t } = useTranslation();
  const question = useSelector((state: RootState) => state.player.question);
  const room = useSelector((state: RootState) => state.player.code);
  const name = useSelector((state: RootState) => state.player.playerName);
  const [show, setShow] = useState(1);
  const [showError, setShowError] = useState(false);
  const [lie, setLie] = useState("");
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [h, setH] = useState({});

  useEffect(() => {
    if (window.innerWidth < 1024) setH({ height: window.innerHeight });
  }, []);

  useEffect(() => {
    socket.off("CHANGE_LIE");
    socket.off("LYING_OVER");

    socket.on("CHANGE_LIE", (data) => {
      if (data.name === name) {
        setShowError(true);
        setShow(1);
        dispatch(setLastAnswer(""));
      }
    });
    socket.on("LYING_OVER", (data) => {
      setShow(3);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendLie = (lie: string) => {
    if (lie.trim() !== "") {
      setShow(2);
      dispatch(setLastAnswer(lie));
      socket.emit("MY_LIE", { roomId: room, lie: lie, name: name });
      setShowError(false);
    }
  };
  return (
    <div
      id="lie"
      className=" w-screen h-screen bg-join-background bg-cover z-0 overflow-hidden"
      style={h}
    >
      {show === 1 && (
        <div
          className="w-screen h-screen items-center flex flex-col overflow-y-auto"
          style={h}
        >
          <h3 className="text-black xs:text-lg text-3xl font-thin desktop:text-7xl font-Cairo z-30 xs:mt-4 mt-20 desktop:mt-36 mx-8 desktop:mx-24 text-center">
            {question}
          </h3>
          <h3 className="text-black xs:text-lg font-bold text-3xl desktop:text-7xl font-Inter z-30 xs:mt-4 mt-12 desktop:mt-36 text-center">
            {t("lie.enter")}
          </h3>
          <input
            type="text"
            className=" w-[85%] desktop:mt-10 desktop:text-5xl desktop:w-[50%] h-16 opacity-80 rounded-lg text-black text-xl border-2 border-darker-blue z-30"
            maxLength={30}
            onChange={(e) => {
              setLie(e.target.value);
            }}
          ></input>
          {showError && (
            <h2 className=" text-red xs:text-lg desktop:text-3xl text-2xl font-Inter font-bold py-2 z-30">
              {t("lie.error")}
            </h2>
          )}
          <button
            className="h-28 w-28 desktop:h-64 desktop:w-64 desktop:text-4xl desktop:mt-24 bg-light-blue text-darker-blue text-3xl font-Cairo rounded-full mt-8 border-2 border-dark-blue z-30"
            onClick={() => sendLie(lie)}
          >
            {t("lie.send")}
          </button>
        </div>
      )}
      {show === 2 && (
        <div
          className="w-screen h-screen flex flex-col justify-start"
          style={h}
        >
          <h3 className="text-black xs:text-xl text-4xl font-bold desktop:text-7xl font-Cairo z-30 xs:mt-8 mt-20 desktop:mt-36 mx-8 desktop:mx-24 text-center">
            {t("lie.waiting")}
          </h3>
        </div>
      )}
      {show === 3 && (
        <div
          className="w-screen h-screen flex flex-col justify-start"
          style={h}
        >
          <h3 className="text-black xs:text-xl text-4xl font-bold desktop:text-7xl font-Cairo z-30 phone:mb-20 mt-36 desktop:mt-48 mx-8 desktop:mx-24 text-center">
            {t("lie.all_lies_entered")}
          </h3>
        </div>
      )}
    </div>
  );
}
