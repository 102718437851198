import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { ProgressBar } from "./ProgressBar";
import {
  IPlayer,
  setProgressBarTitle,
  setResponses,
  shuffleSuggestions,
  addSuggestion,
} from "../../store/slices/MainScreenSlice";
import { Suspense, useContext, useEffect, useState } from "react";
import { setQuestionPage } from "../../store/slices/CycleQuestionSlice";
import { SocketContext } from "../../context/socket";
import { bgVariants } from "../../utils/constants";
import { PlayerContext } from "../../context/howler";
import bg1 from "../../assets/1.svg";
import bg2 from "../../assets/2.svg";
import bg3 from "../../assets/3.svg";
import bg4 from "../../assets/4.svg";
import bg5 from "../../assets/5.svg";
import bg6 from "../../assets/6.svg";
import bg7 from "../../assets/7.svg";
import Bulb from "../svgs/Bulb";
import { motion } from "framer-motion";

export function Question() {
  const question = useSelector(
    (state: RootState) => state.mainScreen.currentQuestion
  );
  const questionNumber = useSelector(
    (state: RootState) => state.mainScreen.questionNumber
  );
  const room = useSelector((state: RootState) => state.mainScreen.roomCode);
  const players = useSelector((state: RootState) => state.mainScreen.players);
  const suggestions = useSelector(
    (state: RootState) => state.mainScreen.suggestions
  );
  const currentQuestion = useSelector(
    (state: RootState) => state.mainScreen.currentQuestion
  );
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const howler = useContext(PlayerContext);
  // eslint-disable-next-line
  const [bg, setBg] = useState(bgVariants[Math.floor(Math.random() * 7)]);
  const [startTimer, setstartTimer] = useState(false);
  const bgs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7];
  const [imagesLoaded, setImagesLoaded] = useState(false);
  useEffect(() => {
    dispatch(setResponses([]));
    dispatch(setProgressBarTitle("question.enter_lies"));
    socket.emit("ENTER_LIES", { room: room, question: question });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    socket.off("MY_LIE");
    socket.on("MY_LIE", (payload) => {
      if (
        currentQuestion.answer !== payload.lie &&
        !suggestions.some((element) => {
          return element.lie === payload.lie;
        })
      )
        dispatch(
          addSuggestion({
            lie: payload.lie,
            name: payload.name,
            our: false,
            truth: false,
            showCup: false,
            points: 0,
          })
        );
      else {
        socket.emit("CHANGE_LIE", payload);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, socket]);

  const loaded = () => {
    setImagesLoaded(true);
    howler.playQuestion(
      currentQuestion.questionSFX,
      questionNumber === 0,
      startTimerCb
    );
  };

  const startTimerCb = () => {
    setstartTimer(true);
  };

  const timer = (s: number) => {
    socket.emit("LYING_OVER", { room: room });
    dispatch(shuffleSuggestions({}));
    if (s !== 0) howler.playTimeOver();
    setTimeout(() => {
      dispatch(setQuestionPage("answer"));
    }, 2000);
  };

  const playCounter = () => {
    howler.playCounterEnd("timesup_countdown.mp3");
  };

  const getColor = (player: IPlayer) => {
    let index = suggestions.findIndex((element) => {
      return element.name === player.name;
    });
    if (index >= 0) {
      return player.color;
    }
    return "#BCBCBC";
  };

  const getVfx = (player: IPlayer) => {
    let index = suggestions.findIndex((element) => {
      return element.name === player.name;
    });
    if (index >= 0) {
      return player.voiceOver;
    }
    return "";
  };

  return (
    <Suspense>
      <div className="relative w-screen h-screen z-0 bg-orange">
        <div
          className={`relative w-screen h-screen z-10 ${
            !imagesLoaded ? "hidden" : ""
          }`}
        >
          <img
            alt="background"
            onLoad={() => loaded()}
            className="w-screen h-screen absolute object-cover z-10"
            src={bgs[bg.id]}
          ></img>
          <div className="h-[100%] w-[100%] flex flex-col justify-between z-30">
            <div className="flex justify-center items-center pt-32 z-30">
              <motion.h1
                initial={{ scale: 0.1, rotate: 180, translateY: 200 }}
                animate={{ scale: 1, rotate: 0, translateY: 0 }}
                transition={{ duration: 2 }}
                className="shrink w-[80%] mx-[10%] text-dark-blue text-8xl text-center z-30"
              >
                {question.question}
              </motion.h1>
            </div>
            <div className="grow flex flex-row w-[100%] h-20 items-center justify-center z-40">
              {players.map((player, index) => {
                return (
                  <Bulb
                    key={index}
                    color={getColor(player)}
                    vfx={getVfx(player)}
                  ></Bulb>
                );
              })}
            </div>
          </div>
          <div className="absolute w-screen h-28 bottom-0 z-30">
            <ProgressBar
              start={startTimer}
              interval={30}
              type="question"
              playCounter={playCounter}
              handleTimer={timer}
              color={bg.ProgressBarColor}
              textColor={bg.ProgressBarTextColor}
            />
          </div>
        </div>
      </div>
    </Suspense>
  );
}
