import React from "react";
import { Blog } from "../../pages/Blog";

export function Card(props: {
  blog: Blog;
  onSelect: React.Dispatch<React.SetStateAction<Blog>>;
}) {
  return (
    <div
      onClick={() => props.onSelect(props.blog)}
      className="w-full aspect-[16/9] rounded-xl flex justify-center items-center backdrop-brightness-50 hover:backdrop-brightness-100 transition-all duration-300 ease-in-out cursor-pointer"
      style={{
        backgroundImage: `url(${props.blog.imgSrc})`,
        backgroundSize: "cover",
      }}
    >
      <h1 className="text-3xl phone:text-xl text-center text-white font-bold font-Inter z-50">
        {props.blog.title}
      </h1>
    </div>
  );
}
