import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { Suspense, useEffect } from 'react';
import { SetShowRound } from "../../store/slices/CycleQuestionSlice";
import { setRoundNumber } from "../../store/slices/MainScreenSlice";
import { Round } from "../Mainscreen/Round";
import { Cycle } from "./Cycle";


export function Rounds() {
    const showRound = useSelector((state: RootState) => state.cycle.showRound)
    const round = useSelector((state: RootState) => state.mainScreen.roundNumber)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(SetShowRound(true))
        dispatch(setRoundNumber(1))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Suspense>
            {(round !== 0 && showRound) && <Round></Round>}
            {(round !== 0 && !showRound) && <Cycle></Cycle>}
        </Suspense>
    )
}