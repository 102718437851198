import { createSlice } from "@reduxjs/toolkit";
import { isExpired } from "react-jwt";

import Cookies from "js-cookie";

const initialState = {
  data: [],
  user: {} as IUser,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: "",
  hasPropaganda: false,
  refresh: false,
  authenticated: false,
  familyFriendly: localStorage.getItem("familyFriendly")?localStorage.getItem("familyFriendly")==="true":true,
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state, action) => {
      Cookies.set("authenticated", JSON.stringify(false), {
        secure: false,
        sameSite: "lax",
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
      Cookies.set("token", "", {
        secure: false,
        sameSite: "lax",
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
      Cookies.set("user", JSON.stringify({}), {
        secure: false,
        sameSite: "lax",
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
      const cookies = Cookies.get();
      for (const cookieName in cookies) {
        if (cookieName !== "language") {
          console.log(cookieName);
          Cookies.remove(cookieName, { path: "/" });
        }
      }
      state.user = {} as IUser;
      state.authenticated = false;
      state.hasPropaganda = false;
    },
    initUser: (state, action) => {
      const token = Cookies.get("token");
      const authenticated = JSON.parse(Cookies.get("authenticated") || "null");
      const user = JSON.parse(Cookies.get("user") || "null");
      if (token && !isExpired(token)) {
        state.authenticated = authenticated;
        state.user = user;
        if (user.game.find((g: IGame) => g.name === "propaganda")) {
          state.hasPropaganda = true;
        }
      } else {
        Cookies.set("authenticated", JSON.stringify(false), {
          secure: false,
          sameSite: "lax",
          path: "/",
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: 30,
        });
        Cookies.set("token", "", {
          secure: false,
          sameSite: "lax",
          path: "/",
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: 30,
        });
        Cookies.set("user", JSON.stringify({}), {
          secure: false,
          sameSite: "lax",
          path: "/",
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: 30,
        });
        const cookies = Cookies.get();
        for (const cookieName in cookies) {
          if (cookieName !== "language")
            Cookies.remove(cookieName, {
              path: "/",
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
            });
        }
        state.user = {} as IUser;
        state.authenticated = false;
      }
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthenticated: (state, action) => {
      state.authenticated = true;
      const user = action.payload.user;
      state.user = user;
      if (user.game.find((g: IGame) => g.name === "propaganda")) {
        state.hasPropaganda = true;
      }
      Cookies.set("authenticated", JSON.stringify(true), {
        secure: false,
        sameSite: "lax",
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
      Cookies.set("token", action.payload.token, {
        secure: false,
        sameSite: "lax",
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
      Cookies.set("user", JSON.stringify(action.payload.user), {
        secure: false,
        sameSite: "lax",
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
      state.refresh = true;
    },
    setHasPropaganda: (state, action) => {
      state.hasPropaganda = action.payload;
    },
    setRefresh: (state, action) => {
      state.refresh = false;
    },
    setFamilyFriendly: (state, action) => {
      state.familyFriendly = action.payload;
      localStorage.setItem("familyFriendly", action.payload);
    },
  },
});

export interface IUser {
  name?: string;
  email?: string;
  familyName?: boolean;
  phone?: string;
  game?: Array<IGame>;
}

export interface IGame {
  name?: string;
  description?: string;
  price?: boolean;
}

// Action creators are generated for each case reducer function
export const {
  setAuthenticated,
  logout,
  initUser,
  setHasPropaganda,
  setRefresh,
  setFamilyFriendly,
  setUser,
} = UserSlice.actions;

export default UserSlice.reducer;
