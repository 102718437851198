import React from "react";
import { Navigate } from "react-router-dom";
import { isExpired } from "react-jwt";
import { useDispatch } from "react-redux";
import { logout } from "../store/slices/UserSlice";
import Cookies from 'js-cookie';

const token = Cookies.get('token');

function PrivateRoute(props: any) {
	const dispatch = useDispatch();

	const redirect = () => {

		if (isExpired(token ? token : "")) {
			dispatch(logout({}));
			return (<Navigate to="/login" />)
		}
		else {
			return (props.child)
		}
	}

	return redirect()
}

export default PrivateRoute;