import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import failure from "../../assets/failure.svg";
import failureMob from "../../assets/retroTv.svg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export function PaymentFailure() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Suspense>
      <Helmet>
        <title>Propaganda: Payment result</title>
        <meta
          name="description"
          content="Page for failed payment result."
        ></meta>
      </Helmet>
      <div className="w-full h-full flex flex-row web:bg-orange phone:bg-red items-center justify-center">
        <div className="w-[40%] h-full flex flex-col items-center justify-center phone:hidden web:visible">
          <h1 className="w-[80%] text-red text-9xl text-center font-bold font-Inter mt-4">
            {t("signin.error_title")}
          </h1>
          <h1 className="w-[50%] text-red text-4xl text-center font-bold font-Inter mt-4">
            {t("signin.error")}
          </h1>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/purshase");
            }}
            className="w-[30%] ml-[5%] h-12 bg-light-blue p-2 mt-6 border-2 font-Inter border-black shadow-md shadow-gray-800"
          >
            {t("signin.purshase")}
          </button>
        </div>
        <div className="w-[40%] h-full flex items-center justify-center phone:hidden web:visible">
          <img alt="retro tv" className="w-[70%]" src={failure}></img>
        </div>
        <div className="w-full h-full bg-red flex flex-col items-center justify-between phone:visible web:hidden">
          <div className="grow flex flex-col items-center justify-center gap-2">
            <h1 className="w-[60%] text-landing-yellow text-xl text-center font-Inter mt-4">
              {t("signin.error")}
            </h1>
            <div className="w-[60%]">
              <img
                alt="men screaming"
                className="w-full"
                src={failureMob}
              ></img>
            </div>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/purshase");
            }}
            className="w-[90%] mb-[5%] h-12 bg-light-blue p-2 mt-8 border-2 font-Inter font-bold border-black shadow-lg shadow-black"
          >
            {t("signin.purshase")}
          </button>
        </div>
      </div>
    </Suspense>
  );
}
