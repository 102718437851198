import React, { Suspense } from "react"
import { useTranslation } from "react-i18next";
import flag from "../../assets/flag.svg"
import mic from "../../assets/mic.svg"
import { motion } from "framer-motion";


export function HowToPlay() {
    const { t } = useTranslation();

    const TextMic = (props: { text: string }) => {
        return (
            <div className="flex flex-row items-start">
                <img alt="mic" className="web:h-[100%] h-8" src={mic}></img>
                <h5 className="web:text-3xl text-xl font-bold font-Inter text-black web:font-light web:px-4 px-2">{props.text}</h5>
            </div>
        )
    }

    const TextStep = (props: { text: string, step: number }) => {
        return (
            <div className="flex flex-row items-center  border-b-2 border-red">
                <h2 className="web:text-8xl text-4xl font-Algerian text-red font-bold px-4">{props.step}</h2>
                <h5 className="web:text-4xl text-xl font-Inter text-black font-light px-4 ">{props.text}</h5>
            </div>
        )
    }

    return (
        <Suspense>
            <div className="relative w-full web:h-[160vh] h-[120vh] bg-landing-yellow z-0" id="how-to">
                <img alt='flag' className="absolute web:bottom-0 w-[100%]  z-10" src={flag} />
                <motion.h1 className="absolute w-[100%] text-center web:top-[15vh] top-12 web:text-9xl text-3xl font-bold web:font-normal font-Inter text-red z-30" > {t('how_to.title')}</motion.h1>
                <div className="absolute web:top-[30vh] top-24 web:w-[50%] web:mx-[30%] mr-4 z-30">
                    <div className="grid web:grid-cols-2 grid-cols-1 w-[100%] web:gap-y-8 content-center items-center">
                        <TextMic text={t('how_to.screen')}></TextMic>
                        <TextMic text={t('how_to.players')}></TextMic>
                        <TextMic text={t('how_to.device')}></TextMic>
                        <TextMic text={t('how_to.time')}></TextMic>
                    </div>
                </div>
                <div className="absolute web:top-[65vh] web:w-[60%] w-[90%] top-64 web:mx-[20%] mr-4 z-30">
                    <div className="grid web:grid-cols-2 grid-cols-1 w-[100%] web:gap-y-6 web:gap-x-16 content-center items-center">
                        <TextStep text={t('how_to.s1')} step={1}></TextStep>
                        <TextStep text={t('how_to.s2')} step={2}></TextStep>
                        <TextStep text={t('how_to.s3')} step={3}></TextStep>
                        <TextStep text={t('how_to.s4')} step={4}></TextStep>
                        <TextStep text={t('how_to.s5')} step={5}></TextStep>
                        <TextStep text={t('how_to.s6')} step={6}></TextStep>
                        <TextStep text={t('how_to.s7')} step={7}></TextStep>
                        <TextStep text={t('how_to.s8')} step={8}></TextStep>
                    </div>
                </div>
                <h1 className="absolute bottom-0 w-[100%] text-center  web:text-9xl font-Inter text-3xl font-bold web:font-normal text-red z-30 " > {t('how_to.buy')}</h1>
                {/*<div className="w-full h-[100%] bg-cover web:bg-flag bg-hero-banner-mobile bg-left">
            </div>*/}
            </div>
        </Suspense>
    )
}