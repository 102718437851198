import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setCurrentPage,
  addPlayer,
  addResponse,
  removePlayer,
  reset,
  addPlayers,
} from "../store/slices/MainScreenSlice";
import { SocketContext } from "../context/socket";
import { Lobby } from "../components/Mainscreen/Lobby";
import { Rounds } from "../components/GamingProcess/Rounds";
import { Logos } from "../components/Mainscreen/Logos";
import { useNavigate } from "react-router-dom";
import { PlayerContext } from "../context/howler";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export function MainScreenPage() {
  const currentPage = useSelector(
    (state: RootState) => state.mainScreen.currentPage
  );
  const code = useSelector((state: RootState) => state.mainScreen.roomCode);
  const round = useSelector((state: RootState) => state.mainScreen.roundNumber);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const howler = useContext(PlayerContext);
  const [connectionLost, setConnectionLost] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("ar");
    document.body.dir = "rtl";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socket) {
      socket.off("joined");
      //socket.off("created")
      socket.off("MY_RESPONSE");
      socket.off("NEW_GAME");
      socket.off("SKIP");
      socket.off("PLAY_AGAIN");
      socket.off("EXIT_GAME");
      socket.off("LEAVE");
      socket.off("disconnect");

      socket.on("disconnect", (reason) => {
        setConnectionLost(true);
      });

      socket.on("joined", (data) => {
        let player = data;
        dispatch(addPlayer(player));
      });

      socket.on("MY_RESPONSE", (data) => {
        dispatch(addResponse({ name: data.name, response: data.response }));
      });

      socket.on("NEW_GAME", (data) => {
        howler.stop();
        dispatch(setCurrentPage("logos"));
      });

      socket.on("SKIP", (data) => {
        howler.stop();
        howler.playSkipSfx(() => {
          dispatch(setCurrentPage("cycle"));
        });
      });

      socket.on("PLAY_AGAIN", (data) => {
        howler.stop();
        dispatch(setCurrentPage("lobby"));
        dispatch(reset({}));
      });

      socket.on("EXIT_GAME", (data) => {
        howler.stop();
        navigate("/");
        dispatch(setCurrentPage("logos"));
        window.location.reload();
      });

      socket.on("LEAVE", (data) => {
        //dispatch(setCurrentPage("home"));
        dispatch(removePlayer(data));
      });

      socket.on("REJOIN_HOST", (data) => {
        dispatch(addPlayers(data));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    socket.emit("ROUND", { room: code, number: round });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  useEffect(() => {
    if (currentPage === "home") navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (connectionLost) {
      socket.off("connect");

      socket.on("connect", () => {
        // ...
        setConnectionLost(false);
        socket.emit("REJOIN_HOST", { roomId: code });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionLost]);

  return (
    <div className="font-Inter">
      <Helmet>
        <title>Propaganda: Game Main Screen</title>
        <meta
          name="description"
          content="This the main screen for the game host."
        ></meta>
      </Helmet>
      {currentPage === "logos" && <Logos></Logos>}
      {currentPage === "lobby" && <Lobby></Lobby>}
      {currentPage === "cycle" && <Rounds></Rounds>}
    </div>
  );
}
