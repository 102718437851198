import { createSlice } from "@reduxjs/toolkit";

export const PlayerSlice = createSlice({
  name: "playerSlice",
  initialState: {
    currentPage: "join",
    vip: false,
    code: "",
    playerName: "",
    gameState: "lobby",
    playersNumber: 0,
    lastAnswer: "",
    roundNumber: 1,
    question:
      "اناتيدافوبيا هو الخوف انه في مكان ما في العالم قاعد تناظرك ..... ",
    responses: ["ملكة", "مهرجة", "طرارة", "سمكة", "بياعة"],
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setGameState: (state, action) => {
      state.gameState = action.payload;
    },
    setQuestion: (state, action) => {
      state.question = action.payload;
    },
    setVip: (state, action) => {
      if (state.playerName === action.payload.name)
        state.vip = action.payload.vip;
    },
    setCode: (state, action) => {
      state.code = action.payload;
    },
    setPlayerName: (state, action) => {
      state.playerName = action.payload;
    },
    setRoundNumber: (state, action) => {
      state.roundNumber = action.payload;
    },
    setResponses: (state, action) => {
      //const array = action.payload.filter((item: any) => item.name !== state.playerName)
      //const newArray = array.map((item:any)=>{return item.lie})
      state.responses = action.payload;
    },
    setLastAnswer: (state, action) => {
      state.lastAnswer = action.payload;
    },
    setPlayersNumber: (state, action) => {
      state.playersNumber = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentPage,
  setQuestion,
  setVip,
  setCode,
  setRoundNumber,
  setPlayerName,
  setResponses,
  setLastAnswer,
  setPlayersNumber,
  setGameState,
} = PlayerSlice.actions;

export default PlayerSlice.reducer;
