import React from "react";

export function VideoBanner() {
  return (
    <div className="flex w-full h-[70vh] bg-cover bg-landing-yellow justify-center items-center content-center mt-20">
      <div className="flex h-[95%] web:p-6 phone:p-2 w-[85%] bg-orange justify-center items-center content-center">
        <iframe
          className="w-full h-full"
          src="https://www.youtube.com/embed/N1UNrlveaes"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}
