import React, { useEffect } from "react";
//import { useCookies } from 'react-cookie';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PasswordReset } from "./components/Authentication/PasswordReset";
import { PaymentFailure } from "./components/Authentication/PaymentFailure";
import { SocketContext, socket } from "./context/socket";
import { LandingScreenPage } from "./pages/LandingScreenPage";
import { Login } from "./pages/Login";
import { MainScreenPage } from "./pages/MainScreenPage";
import { PlayerScreen } from "./pages/PlayerScreen";
import { Purshase } from "./pages/Purshase";
import { saveRess } from "./store/slices/MainScreenSlice";
import { useDispatch } from "react-redux";
import { player, PlayerContext } from "./context/howler";
import PrivateRoute from "./components/PrivateRoute";
import UnauthRoute from "./components/UnauthRoute";
import { initUser } from "./store/slices/UserSlice";
import { Result } from "./components/Authentication/Result";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Round } from "./components/Mainscreen/Round";
import { Buffer } from "buffer";
import { ResultCard } from "./components/Authentication/ResultCard";
import { ResultMada } from "./components/Authentication/ResultMada";
import { ResultApple } from "./components/Authentication/ResultApple";
import TagManager from "react-gtm-module";
import { BlogPage } from "./pages/Blog";
import { Signin } from "./pages/Signin";

const tagManagerArgs = {
  gtmId: "GTM-WSZSLJ3N",
};
TagManager.initialize(tagManagerArgs);

function App() {
  //const [cookies, setCookie] = useCookies();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  window.Buffer = Buffer;
  useEffect(() => {
    let language = Cookies.get("language");
    if (!language) language = "ar";
    i18n.changeLanguage(language);
    if (language !== "ar") document.body.dir = "ltr";
    else document.body.dir = "rtl";
    dispatch(initUser({}));
    fetch(process.env.REACT_APP_API_URL + "ressources", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        player.setRessources(data);
        dispatch(saveRess(data));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      <PlayerContext.Provider value={player}>
        <BrowserRouter>
          <Routes>
            <Route path="/landing" element={<LandingScreenPage />} />
            <Route path="/" element={<LandingScreenPage />} />
            {/*<Route path='/game' element={<PrivateRoute child={<MainScreenPage />} ></PrivateRoute>} />*/}
            <Route path="/game" element={<MainScreenPage />} />
            <Route path="/join/:room" element={<PlayerScreen />} />
            <Route path="/join" element={<PlayerScreen />} />
            <Route
              path="/login"
              element={<UnauthRoute child={<Login />}></UnauthRoute>}
            />
            <Route
              path="/reset"
              element={<UnauthRoute child={<PasswordReset />}></UnauthRoute>}
            />
            <Route path="/purshase" element={<Purshase />} />
            <Route path="/register" element={<Signin />} />
            <Route path="/result/:success" element={<Result />} />
            <Route path="/result/card" element={<ResultCard />} />
            <Route path="/result/mada" element={<ResultMada />} />
            <Route path="/result/apple" element={<ResultApple />} />
            <Route path="/round" element={<Round />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route
              path="/error"
              element={<PrivateRoute child={<PaymentFailure />}></PrivateRoute>}
            />
          </Routes>
        </BrowserRouter>
      </PlayerContext.Provider>
    </SocketContext.Provider>
  );
}

export default App;
