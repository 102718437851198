import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { ProgressBar } from "./ProgressBar";
import {
  setProgressBarTitle,
  getResults,
  setSuggestionPoints,
} from "../../store/slices/MainScreenSlice";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { SocketContext } from "../../context/socket";
import { ButtonResult } from "./ButtonResult";
import { setQuestionPage } from "../../store/slices/CycleQuestionSlice";
import { bgVariants } from "../../utils/constants";
import cup from "../../assets/cup.svg";
import { PlayerContext } from "../../context/howler";
import bg1 from "../../assets/1.svg";
import bg2 from "../../assets/2.svg";
import bg3 from "../../assets/3.svg";
import bg4 from "../../assets/4.svg";
import bg5 from "../../assets/5.svg";
import bg6 from "../../assets/6.svg";
import bg7 from "../../assets/7.svg";
import { motion } from "framer-motion";
import { HowlerPlayer } from "../../context/HowlerPlayer";

export function Answer() {
  const question = useSelector(
    (state: RootState) => state.mainScreen.currentQuestion
  );
  const round = useSelector((state: RootState) => state.mainScreen.roundNumber);
  const suggestions = useSelector(
    (state: RootState) => state.mainScreen.suggestionsToSend
  );
  const suggestionss = useSelector(
    (state: RootState) => state.mainScreen.suggestions
  );
  const results = useSelector((state: RootState) => state.mainScreen.results);
  const room = useSelector((state: RootState) => state.mainScreen.roomCode);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const [show, setShow] = useState(true);
  const [hide, setHide] = useState(false);
  // eslint-disable-next-line
  const [bg, setBg] = useState(bgVariants[Math.floor(Math.random() * 7)]);
  //const [bg, setBg] = useState(window.location.origin + "/images/" + (Math.floor(Math.random() * 7) + 1) + ".svg")
  const [respNumber, setRespNumber] = useState(0);
  const howler = useContext(PlayerContext);
  const bgs = [bg1, bg2, bg3, bg4, bg5, bg6, bg7];
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    dispatch(setProgressBarTitle("question.answer"));
    socket.emit("ENTER_RESPONSES", {
      room: room,
      question: question,
      suggestions: suggestions,
      withNames: suggestionss,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timer = (s: number) => {
    howler.stop();
    if (s !== 0) {
      howler.playGameTrack("all_players_answered.mp3", false, HowlerPlayer.sfxVolume, () => {
        try {
          howler.stop();
        } catch (e) {
          console.log(e);
        }
      });
    }
    setHide(true);
    setTimeout(() => {
      howler.playBg("choose_answer_music.mp3");
      dispatch(getResults({}));
      setShow(false);
    }, 2000);
    /*if (round !== 3)
            dispatch(setQuestionPage("result"))
        else
            dispatch(setQuestionPage("FinalResults"))*/
  };
  const playCounter = () => {
    howler.playCounterEnd("Pickup_something!!!.mp3");
  };
  const nextResp = (
    truth: boolean,
    name: string,
    points: number,
    next: boolean,
    lie: string
  ) => {
    if (next) {
      dispatch(
        setSuggestionPoints({
          truth: truth,
          our: results[respNumber].response.our,
          name: name,
          points: points,
          players: results[respNumber].players,
          lie: lie,
        })
      );
      setShow(true);
      setTimeout(() => {
        if (respNumber + 1 < results.length) {
          setRespNumber(respNumber + 1);
          setShow(false);
        } else {
          if (round !== 3) dispatch(setQuestionPage("result"));
          else dispatch(setQuestionPage("FinalResults"));
        }
      }, 5000);
    } else {
      if (round !== 3) dispatch(setQuestionPage("result"));
      else dispatch(setQuestionPage("FinalResults"));
    }
  };

  const loaded = () => {
    setImagesLoaded(true);
    howler.playGameTrack("choose_answer_music.mp3", true, HowlerPlayer.musicVolume);
  };

  const getCup = (lie: number) => {
    const index = suggestionss.findIndex((element) => {
      return element.lie === suggestions[lie];
    });
    return suggestionss[index];
  };

  const Cup = (props: { truth: boolean; player: string; score: number }) => {
    return (
      <div className="absolute h-32 w-32 right-0 bottom-[-16px]  z-30">
        <div className="relative h-32 w-32 z-30">
          <img
            alt="cup"
            className="w-32 absolute bottom-0 z-30"
            src={cup}
          ></img>
          <div className="absolute w-[100%] top-1 flex flex-col z-30">
            {!props.truth && (
              <h3 className="shrink text-xl text-dark-blue text-center z-30">
                {props.player}
              </h3>
            )}
            {!props.truth && (
              <h3 className="shrink text-xl text-dark-blue text-center z-30">
                {props.score}+
              </h3>
            )}
            {props.truth && (
              <h3 className="shrink mt-2 text-xl text-dark-blue text-center z-30">
                الصدق
              </h3>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Suspense>
      <div className="relative w-screen h-screen z-0 bg-orange">
        <div
          className={` relative w-screen h-screen z-10 ${
            !imagesLoaded ? "hidden" : ""
          }`}
        >
          <img
            alt="background"
            onLoad={() => loaded()}
            className="w-screen h-screen absolute object-cover z-10"
            src={bgs[bg.id]}
          ></img>
          <div className="flex justify-center items-center h-[30%] z-30">
            {show && (
              <motion.h1
                animate={{ scale: 1 }}
                initial={{ scale: 0.5 }}
                transition={{ type: "tween", duration: 1 }}
                className={`w-[80%] mx-[10%] text-dark-blue font-Cairo text-7xl text-center  z-30`}
              >
                {question.question}
              </motion.h1>
            )}
            {!show && (
              <motion.h1
                animate={{ scale: 0.5 }}
                initial={{ scale: 1 }}
                transition={{ type: "tween", duration: 1 }}
                className={`w-[80%] mx-[10%] text-dark-blue font-Cairo text-7xl text-center  z-30`}
              >
                {question.question}
              </motion.h1>
            )}
          </div>
          {suggestions.length < 7 && show && (
            <div className="grid grid-cols-2 w-[40%] mx-[30%] gap-10 mt-32 content-center  z-30">
              {suggestions.map((suggestion, index) => {
                return (
                  <motion.button
                    animate={{ scale: 1 }}
                    initial={{ scale: 0 }}
                    transition={{ type: "tween", duration: 1 }}
                    key={index}
                    className="relative text-3xl w-full h-20 font-Cairo bg-beige rounded-xl border-2 border-orange shadow-xl  z-30"
                  >
                    {getCup(index).showCup && (
                      <Cup
                        truth={getCup(index).truth || false}
                        score={getCup(index).points || 0}
                        player={getCup(index).name || ""}
                      ></Cup>
                    )}
                    {suggestion}
                  </motion.button>
                );
              })}
            </div>
          )}
          {suggestions.length > 6 && show && (
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 2, delay: 0.5 }}
              className="grid grid-cols-3 w-[70%] mx-[15%] gap-x-14 gap-y-4 mt-32 content-center  z-30"
            >
              {suggestions.map((suggestion, index) => {
                return (
                  <motion.button
                    animate={{ scale: 1 }}
                    initial={{ scale: 0 }}
                    transition={{ type: "tween", duration: 1 }}
                    key={index}
                    className="relative text-3xl w-full h-20 text-dark-blue font-Cairo bg-beige rounded-xl border-2 border-orange shadow-xl  z-30"
                  >
                    {suggestion}
                  </motion.button>
                );
              })}
            </motion.div>
          )}
          {/*!show && <div className='flex justify-center items-center h-[60%]'>  <motion.button animate={{ scale: 1 }} initial={{ scale: 0 }} transition={{ type: 'tween', duration: 1 }} className='text-3xl w-[50%] h-[40%] text-dark-blue font-Cairo bg-beige rounded-xl border-2 border-orange'> xxxxxxxx</motion.button></div>*/}
          {!show && (
            <div className="flex justify-center items-center h-[60%]  z-30">
              {" "}
              <ButtonResult
                goNext={nextResp}
                result={results[respNumber] ? results[respNumber] : null}
              ></ButtonResult>
            </div>
          )}
          {!hide && (
            <div className="absolute w-screen h-24 bottom-0 z-30">
              <ProgressBar
                start={true}
                type="answer"
                interval={30}
                playCounter={playCounter}
                handleTimer={timer}
                color={bg.ProgressBarColor}
                textColor={bg.ProgressBarTextColor}
              />
            </div>
          )}
        </div>
      </div>
    </Suspense>
  );
}
