import { useTranslation } from 'react-i18next';
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { setProgressBarTitle, setSuggestions } from '../../store/slices/MainScreenSlice';
import { setQuestionPage } from '../../store/slices/CycleQuestionSlice';
import { Suspense, useContext, useEffect, useState } from 'react';
import { PlayerContext } from '../../context/howler';
import { motion } from 'framer-motion';
import { HowlerPlayer } from '../../context/HowlerPlayer';


export function Category() {
    const { t } = useTranslation();
    const question = useSelector((state: RootState) => state.mainScreen.currentQuestion)
    const dispatch = useDispatch();
    const howler = useContext(PlayerContext)
    const [bgLoaded, setBgLoaded] = useState(false);
    useEffect(() => {
        const img = new Image();
        img.src = window.location.origin + `/images/bgs/category.svg`;
        img.onload = () => {
            setBgLoaded(true)
            howler.playGameTrack('category_music.mp3', false, HowlerPlayer.musicVolume)
            setTimeout(() => {
                dispatch(setQuestionPage("question"))
            }, 11000);
        };
        dispatch(setSuggestions([]))
        dispatch(setProgressBarTitle('question.answer'))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Suspense>
            {!bgLoaded && <div className='flex w-screen h-screen bg-orange' >

            </div>
            }
            {bgLoaded && <motion.div className='flex w-screen h-screen' animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{duration: 1.5 }}>
                <h3 className='absolute pr-16 pt-8 text-7xl text-dark-blue' >{t('category.type')}</h3>
                <div className=' grow flex w-[45%] items-center bg-light-blue'>
                    <motion.h3 animate={{ scale: 1.1 }} initial={{ scale: 0.95 }} transition={{ repeat: Infinity, repeatType: "reverse", duration: 1 }} className=' w-[100%] text-9xl text-dark-blue text-center' >{question.category}</motion.h3>
                </div>
                <div className='w-[55%] bg-category-background bg-cover'>

                </div>
            </motion.div>}
        </Suspense>
    )
}