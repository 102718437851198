import { useTranslation } from 'react-i18next';
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Suspense, useContext, useEffect, useState } from 'react';
import { SocketContext } from '../../context/socket';
import winner from '../../assets/winner.svg'
import propaganda from '../../assets/propaganda.svg'
import { PlayerContext } from '../../context/howler';
import CountUp from 'react-countup';
import { HowlerPlayer } from '../../context/HowlerPlayer';


export function FinalResults() {
	const { t } = useTranslation();
	const players = useSelector((state: RootState) => state.mainScreen.players)
	const [resultPage, setResultPage] = useState(0)
	const [winners, setWinners] = useState([""])
	const socket = useContext(SocketContext)
	const room = useSelector((state: RootState) => state.mainScreen.roomCode)
	const howler = useContext(PlayerContext)
	useEffect(() => {
		getWinners()
		howler.playGameTrack("final_round.mp3", false, HowlerPlayer.musicVolume)
		setTimeout(() => {
			howler.stopBg();
			setResultPage(1)
			howler.playGameTrack("results_music.mp3", false, HowlerPlayer.musicVolume)
		}, 13000)
		setTimeout(() => {
			setResultPage(2)
			howler.playGameTrack("winner.mp3", false, HowlerPlayer.musicVolume)
		}, 20000)
		setTimeout(() => {
			setResultPage(3)
			howler.playGameTrack("credtt_music.mp3", false, HowlerPlayer.musicVolume)
			socket.emit("GAME_OVER", { room: room })
		}, 31000)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getWinners = () => {
		let first = players[0].score;
		let array: string[] = []
		players.forEach((p) => {
			if (p.score === first)
				array.push(p.name)
		})
		setWinners(array)
	}

	return (
		<Suspense>
			{(resultPage === 0) && <div className='flex w-screen h-screen bg-darker-blue'>

				<div className='w-[55%] bg-tvs-background bg-cover' />
				<div className=' grow flex flex-col w-[45%] items-center justify-center bg-darker-blue'>

					<h3 className='text-9xl text-orange font-Cairo' >{t('results.final')}</h3>
				</div>
			</div>}
			{(resultPage === 1) && <div className='flex w-screen h-screen '>
				<h3 className='absolute pr-16 pt-8 text-7xl text-white font-Cairo' >{t('results.title')}</h3>
				<div className=' grow flex flex-col w-[40%] items-center justify-center bg-orange'>
					{
						players.map((player, index) => {
							return (<div key={index} className='w-[70%] flex justify-between py-4 border-b-2 border-dark-blue' >
								<h3 className='text-4xl text-dark-blue font-Cairo' >{player.name}</h3>
								<CountUp className='text-5xl text-dark-blue font-Algerian' end={player.score} duration={(5 / players.length) * (players.length - index)} ></CountUp>
							</div>)
						})
					}
				</div>
				<div className='w-[60%] bg-accountant bg-cover bg-center bg-no-repeat' />
			</div>}
			{(resultPage === 2) && <div className='flex w-screen h-screen '>
				<h3 className='absolute pr-16 pt-8 text-7xl text-white font-Cairo' >{t('results.winner')}</h3>
				<div className=' grow flex flex-col w-[45%] items-center justify-center bg-orange'>
					{
						winners.map((player, index) => {
							return (<h3 key={index} className='text-8xl text-dark-blue font-Cairo' >{player}</h3>)
						})
					}

				</div>
				<div className='w-[55%] flex items-center justify-center bg-orange' >
					<img alt="winner" src={winner}></img>
				</div>
			</div>}
			{(resultPage === 3) && <div className='flex w-screen h-screen bg-darker-blue'>

				<div className='w-[55%] bg-laser-background bg-cover' />
				<div className=' grow flex flex-col w-[45%] items-center bg-darker-blue'>
					<div className='w-[75%] flex items-start'>
						<img alt="logo propaganda" className='shrink' src={propaganda}></img>
					</div>

					{
						players.map((player, index) => {
							return (<div key={index} className='w-[70%] flex justify-between border-b-2 border-orange' >
								<h3 className='text-4xl text-white font-Cairo' >{player.name}</h3>
								<h3 className='text-5xl text-white font-Algerian' >{player.score}</h3>
							</div>)
						})
					}
					<h3 className='text-6xl pt-24 text-white font-Cairo' >{t('results.play_again')}</h3>
					<div className='w-[70%] mt-16 flex justify-between' >
						<button className='w-[45%] h-14 font-Cairo text-dark-blue text-2xl bg-orange border-2 border-white rounded-lg'>{t('results.yes_diff')}</button>
						<button className='w-[45%] h-14 font-Cairo text-dark-blue text-2xl bg-orange border-2 border-white rounded-lg'>{t('results.yes_same')}</button>
					</div>
					<div className='w-[70%] mt-16 flex justify-center' >
						<button className='w-[45%] h-14 font-Cairo text-dark-blue text-3xl bg-light_green border-2 border-white rounded-lg'>{t('results.exit')}</button>
					</div>
				</div>
			</div>}
		</Suspense>
	)
}