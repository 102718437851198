import { Howl } from "howler";

export class HowlerPlayer {
  public static narratorVolume = parseFloat(localStorage.getItem("narratorVolume") || "0.7");
  public static sfxVolume = parseFloat(localStorage.getItem("sfxVolume") || "0.1");
  public static musicVolume = parseFloat(localStorage.getItem("musicVolume") || "0.1");
  public player: Howl = new Howl({
    src: " ",
    format: ["mp3"],
  });
  private playerBg: Howl = new Howl({
    src: " ",
    format: ["mp3"],
  });
  private currentTrack: number = 0;
  private tracks: Howl[] = [
    new Howl({
      src: " ",
      format: ["mp3"],
    }),
  ];
  private ressources: any;
  private stopPlaylist = false;
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() { }

  init(tracks: Array<{ url: string; title: string }>) {
    this.tracks = [];
    tracks.forEach((track) => {
      this.tracks.push(
        new Howl({
          src: track.url,
          format: ["mp3"],
        })
      );
    });
    this.player = this.tracks[this.currentTrack];
  }

  play() {
    this.player.play();
  }

  playBg(track: string) {
    this.playerBg = new Howl({
      src: this.ressources["gameSFX"][track],
      loop: true,
      volume: HowlerPlayer.musicVolume,
      format: ["mp3"],
    });
    this.playerBg.play();
  }

  playPlayerVFX(vfx: string) {
    const p = new Howl({
      src: this.ressources["playersSFX"][vfx],
      volume: HowlerPlayer.sfxVolume,
      format: ["mp3"],
    });
    p.play();
    p.on("end", () => {
      p.stop();
    });
  }

  playGameTrack(
    track: string,
    loop: boolean,
    volume: number,
    callback?: Function
  ) {
    try {
      this.stop();
    } catch (exp) {
      console.log(exp);
    }
    this.player = new Howl({
      src: this.ressources["gameSFX"][track],
      loop: loop,
      volume: volume,
      format: ["mp3"],
    });
    this.player.play();
    if (callback)
      this.player.on("end", () => {
        callback();
      });
  }

  playListIntro(playerNumb: number) {
    try {
      this.stop();
    } catch (exp) {
      console.log(exp);
    }
    this.stopPlaylist = false;
    this.playGameTrack("intro.mp3", false, HowlerPlayer.musicVolume);
    const trackss = [
      { track: this.ressources["gameSFX"][`table_for_${playerNumb}.mp3`], volume: HowlerPlayer.narratorVolume },
      { track: this.ressources["gameSFX"]["game_instructions.mp3"], volume: HowlerPlayer.narratorVolume },
    ];
    this.tracks = [];
    trackss.forEach((track) => {
      this.tracks.push(
        new Howl({
          src: track?.track,
          volume: track?.volume,
          format: ["mp3"],
        })
      );
    });
    this.tracks.forEach((track, index) => {
      track.on("end", () => {
        if (index < this.tracks.length - 1) {
          this.currentTrack = index + 1;
          this.tracks[this.currentTrack].play();
        }
      });
    });
    this.tracks[this.currentTrack].play();
  }

  playTruth(answerSfx: string) {
    new Howl({
      src: this.ressources["gameSFX"]["and_the_right_answer_is.mp3"],
      loop: false,
      volume: HowlerPlayer.narratorVolume,
      format: ["mp3"],
    })
      .on("end", () => {
        this.playGameTrack("truth_sound_FX.mp3", false, HowlerPlayer.sfxVolume);
        new Howl({
          src: answerSfx,
          loop: false,
          volume: HowlerPlayer.narratorVolume,
          format: ["mp3"],
        }).play();
      })
      .play();
  }

  playQuestion(track: string, first: boolean, callback: Function) {
    this.currentTrack = 0;
    this.stop();
    this.stopPlaylist = false;
    let trackss = [];
    if (first)
      trackss = [
        { track: this.ressources["gameSFX"][`first_question.mp3`], volume: HowlerPlayer.narratorVolume },
        { track: track, volume: HowlerPlayer.narratorVolume },
        { track: this.ressources["gameSFX"][`waiting_for_answers.mp3`], volume: HowlerPlayer.musicVolume }
      ];
    else
      trackss = [
        { track: this.ressources["gameSFX"][`next_question.mp3`], volume: HowlerPlayer.narratorVolume },
        { track: track, volume: HowlerPlayer.narratorVolume },
        { track: this.ressources["gameSFX"][`waiting_for_answers.mp3`], volume: HowlerPlayer.musicVolume },
      ];
    this.tracks = [];
    trackss.forEach((track) => {
      this.tracks.push(
        new Howl({
          src: track?.track,
          volume: track?.volume,
          format: ["mp3"],
        })
      );
    });
    this.tracks.forEach((track, index) => {
      track.on("end", () => {
        if (index === 1) {
          callback();
        }
        if (!this.stopPlaylist && index < this.tracks.length - 1) {
          this.currentTrack = index + 1;
          this.tracks[this.currentTrack].play();
        }
      });
    });
    this.tracks[this.currentTrack].play();
  }

  playCounterEnd(track: string, handleEnd?: () => void) {
    let howl = new Howl({
      src: this.ressources["gameSFX"][track],
      volume: HowlerPlayer.sfxVolume,
      format: ["mp3"],
    });
    howl.play();
    howl.on("end", () => {
      try {
        //this.stop();
        if (handleEnd) handleEnd();
      } catch (exp) {
        console.log(exp);
      }
    });
    /*try {
      this.tracks[this.currentTrack].volume(0.3);
    } catch (exp) {
      console.log(exp);
    }
    try {
      this.player.volume(0.3);
    } catch (exp) {
      console.log(exp);
    }*/
  }

  playResultsSfx() {
    this.currentTrack = 0;
    this.stopPlaylist = false;
    const array = ["players_on_fire.mp3", "no_bullying_please.mp3"];
    const trackss = [
      { track: this.ressources["gameSFX"]["results_music.mp3"], volume: HowlerPlayer.musicVolume },
      { track: this.ressources["gameSFX"][array[Math.round(Math.random())]], volume: HowlerPlayer.narratorVolume },
    ];
    this.tracks = [];
    trackss.forEach((track) => {
      this.tracks.push(
        new Howl({
          src: track?.track,
          volume: track?.volume,
          format: ["mp3"],
        })
      );
    });
    this.tracks.forEach((track, index) => {
      track.on("end", () => {
        if (!this.stopPlaylist && index < this.tracks.length - 1) {
          this.currentTrack = index + 1;
          this.tracks[this.currentTrack].play();
        }
      });
    });
    this.tracks[this.currentTrack].play();
  }

  playSkipSfx(handleEnd: any) {
    this.currentTrack = 0;
    this.stopPlaylist = false;
    const trackss = [
      this.ressources["gameSFX"]["dj-disc-stop.mp3"],
      this.ressources["gameSFX"]["dont_push.mp3"],
    ];
    this.tracks = [];
    trackss.forEach((track) => {
      this.tracks.push(
        new Howl({
          src: track,
          volume: HowlerPlayer.sfxVolume,
          format: ["mp3"],
        })
      );
    });
    this.tracks.forEach((track, index) => {
      track.on("end", () => {
        if (!this.stopPlaylist && index < this.tracks.length - 1) {
          this.currentTrack = index + 1;
          this.tracks[index + 1].play();
        } else {
          handleEnd();
        }
      });
    });
    this.tracks[this.currentTrack].play();
  }

  playTimeOver() {
    this.stopPlaylist = true;
    this.tracks[this.currentTrack].stop();
    this.player = new Howl({
      src: this.ressources["gameSFX"]["all_players_answered.mp3"],
      volume: HowlerPlayer.sfxVolume,
      format: ["mp3"],
    });
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  stop() {
    this.stopPlaylist = true;
    try {
      this.tracks[this.currentTrack].stop();
      this.currentTrack = 0;
    } catch (exp) {
      console.log(exp);
    }
    this.player.stop();
  }
  stopBg() {
    this.playerBg.stop();
  }

  prevTrack() {
    if (this.currentTrack === 0) {
      this.currentTrack = this.tracks.length - 1;
    } else {
      this.currentTrack--;
    }
    this.player = this.tracks[this.currentTrack];
  }

  nextTrack() {
    if (this.currentTrack === this.tracks.length - 1) {
      this.currentTrack = 0;
    } else {
      this.currentTrack++;
    }
    this.player = this.tracks[this.currentTrack];
  }

  setRessources(res: any) {
    this.ressources = res;
  }
}
