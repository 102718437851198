import { useTranslation } from 'react-i18next';
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect, useState } from 'react';
import { SocketContext } from '../../context/socket';
import { setCurrentPage } from '../../store/slices/PlayerSlice';
import propagandaPlayer from '../../assets/propaganda-player.svg';

export function Skip() {
    const { t } = useTranslation();
    const code = useSelector((state: RootState) => state.player.code)
    const socket = useContext(SocketContext);
    const dispatch = useDispatch();
    const playerImageAlt = "player image"
    const [h, setH] = useState({})

    useEffect(() => {
        if (window.innerWidth < 1024)
            setH({ height: window.innerHeight })
    }, [])
    const startGame = () => {
        socket.emit("SKIP", { roomId: code })
        dispatch(setCurrentPage("round"))
    }

    return (
        <div id='skip' className=' w-screen h-screen bg-join-background bg-cover z-0  overflow-hidden' style={h}>
            <div className='absolute w-screen h-screen bg-orange opacity-40 z-10' style={h} />

            <div className='w-screen h-screen items-center flex flex-col justify-between' style={h}>
                <img alt={playerImageAlt} className='shrink z-30 xs:mt-8 mt-16 desktop:h-44' src={propagandaPlayer}></img>
                <button className='h-28 w-28 desktop:h-56 desktop:w-56 desktop:text-5xl phone:mb-4 desktop:mt-24 bg-btn-red text-darker-blue text-3xl font-Cairo rounded-full mt-8 border-2 border-dark-blue z-30 disabled:opacity-50' onClick={startGame} >{t('join.skip')}</button>
            </div>

        </div >

    )
}