import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import propaRed from "../../assets/porpa.svg";
import mic from "../../assets/bigMic.svg";
import { useNavigate } from "react-router-dom";

export function PaymentSuccess() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Suspense>
      <div className="w-full h-full overflow-y-auto">
        <div className="w-full h-full flex flex-row items-center justify-center pt-[5%] phone:hidden web:visible">
          <div className="w-[15%]"></div>
          <div className="w-[50%] h-full flex flex-row bg-orange justify-center phone:hidden web:visible">
            <div className="w-[45%]">
              <img alt="men screaming" className="w-full" src={propaRed}></img>
            </div>
            <div className="w-full flex flex-col items-center justify-start">
              <h1 className="w-[80%] text-red text-5xl text-center font-bold font-Inter mt-4">
                {t("signin.purshase_succ")}
              </h1>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
                className="w-[50%] ml-[5%] h-12 bg-light-blue p-2 mt-6 border-2 font-Inter border-black shadow-md shadow-gray-800"
              >
                {t("signin.go_home")}
              </button>
            </div>
          </div>
          <div className="w-[30%]"></div>
        </div>

        <div className="w-full h-full bg-red flex flex-col items-center justify-between phone:visible web:hidden">
          <div className="grow flex flex-col items-center justify-center gap-6">
            <h1 className="w-[60%] text-landing-yellow text-xl text-center font-Inter mt-4">
              {t("signin.purshase_succ")}
            </h1>
            <div className="w-[60%]">
              <img alt="men screaming" className="w-full" src={mic}></img>
            </div>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate("/");
            }}
            className="w-[90%] mb-[5%] h-12 bg-light-blue p-2 mt-12 border-2 font-Inter font-bold border-black shadow-lg shadow-black"
          >
            {t("signin.go_home")}
          </button>
        </div>
      </div>
    </Suspense>
  );
}
