import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { Suspense, useEffect } from 'react';
import { setQuestionPage } from "../../store/slices/CycleQuestionSlice";
import { Category } from "../Mainscreen/Category";
import { Question } from "../Mainscreen/Question";
import { Answer } from "../Mainscreen/Answer";
import { Results } from "../Mainscreen/Results";
import { FinalResults } from "../Mainscreen/FinalResults";


export function Cycle() {
    const questionPage = useSelector((state: RootState) => state.cycle.questionPage)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setQuestionPage("category"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Suspense>
            {questionPage === "category" && <Category></Category>}
            {questionPage === "question" && <Question></Question>}
            {questionPage === "answer" && <Answer></Answer>}
            {questionPage === "result" && <Results></Results>}
            {questionPage === "FinalResults" && <FinalResults></FinalResults>}
        </Suspense>
    )
}