import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import propa from "../../assets/propa-mob.svg";
import propaRed from "../../assets/porpa.svg";
import propaYell from "../../assets/propa-reset-mob.svg";
import {
  useForgotPwdMutation,
  useResetPwdMutation,
} from "../../store/Apis/UserApi";
import { useNavigate } from "react-router-dom";

export function PasswordReset() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    reset_password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});
  const [forgot] = useForgotPwdMutation();
  const [reset] = useResetPwdMutation();
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({ ...formData, email: email });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const forgotPwd = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = regex.test(email);
    if (valid) {
      setError("");
      //call api
      forgot({ email: email })
        .unwrap()
        .then((data) => {
          setPage(2);
        })
        .catch((err) => {
          console.log(err);
          if (err.data.error_code)
            setError(t("errors." + err.data.error_code) || err.data.error);
          else setError(err.data.error);
        });
    } else {
      setError("Invalid email address");
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // Form can be submitted
      reset(formData)
        .unwrap()
        .then((data) => {
          //dispatch(setAuthenticated(data))
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          if (err.data.error_code)
            setErrors({
              error: t("errors." + err.data.error_code) || err.data.error,
            });
          else setErrors({ error: err.data.error });
        });
    } else {
      setErrors(validationErrors);
    }
  };
  const validateInput = (name: string, value: string) => {
    switch (name) {
      case "password":
        return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          value
        )
          ? null
          : t("errors.WEAK_PWD") ||
              "password should be at least 8 characters with at least 1 number and 1 symbol";
      case "confirm_password":
        return value === formData.password
          ? null
          : t("errors.NOT_MATCHING_PWD") || "passwords not matching";
      default:
        return null;
    }
  };

  const validateForm = () => {
    const validationErrors: any = {};
    for (const [name, value] of Object.entries(formData)) {
      const err = validateInput(name, value);
      if (err) validationErrors[name] = err;
    }
    return validationErrors;
  };

  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    //setErrors({ ...errors, [event.target.name]: validateInput(event.target.name, event.target.value) });
  };

  return (
    <Suspense>
      {page === 1 && (
        <div>
          <div className="w-screen h-screen flex bg-orange items-center justify-center phone:hidden web:visible">
            <div className="flex flex-col w-[50%]  items-end">
              <h1 className="w-full text-center text-9xl text-red font-bold font-Inter">
                {t("login.forgot")}
              </h1>
              <input
                type="email"
                className="w-[90%] h-16 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-6 mt-20"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                placeholder={t("login.email") || "email"}
              ></input>
              <h3 className="w-[88%] text-xl text-black font-Inter mt-2">
                {t("login.note")}
              </h3>
              {error && (
                <h3 className="text-red text-xl font-bold w-[88%] ">{error}</h3>
              )}
              <button
                onClick={() => {
                  forgotPwd();
                }}
                className="w-[30%] h-12 bg-light-blue p-2 mt-16 border-2 font-Inter font-bold border-black shadow-lg"
              >
                {t("login.send")}
              </button>
            </div>
          </div>

          <div className="flex flex-col bg-red h-screen w-screen items-end phone:visible web:hidden">
            <div className="h-[20%]"></div>
            <img alt="men" src={propa} className="w-[50%] ml-4"></img>
            <div className="w-full h-full flex flex-col items-center justify-between">
              <div className="w-full flex flex-col items-center justify-center">
                <input
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  type="email"
                  className="w-[90%] h-16 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-8"
                  placeholder={t("login.email") || "email"}
                ></input>
                <h3 className="w-[75%] ml-[10%] text-lg text-black font-Inter mt-4">
                  {t("login.note")}
                </h3>
                {error && (
                  <h3 className="text-yellow-500 text-sm font-bold ml-[10%] w-[75%] ">
                    {error}
                  </h3>
                )}
              </div>
              <button
                onClick={() => {
                  forgotPwd();
                }}
                className="w-[90%] h-16 bg-light-blue p-2 mb-8 border-2 font-Inter font-bold border-black shadow-lg shadow-black"
              >
                {t("login.send")}
              </button>
            </div>
          </div>
        </div>
      )}

      {page === 2 && (
        <div>
          <div className="w-screen h-screen flex flex-row bg-orange items-center justify-center phone:hidden web:visible">
            <img alt="men" className="w-[25%]" src={propaRed}></img>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col w-[45%] mr-8 items-start"
            >
              <input
                className="w-full h-20 bg-landing-yellow border-2 text-xl border-red font-Inter shadow-lg placeholder:text-rose-500 px-6"
                placeholder={t("login.pwd_recieved") || "email"}
                name="reset_password"
                value={formData.reset_password}
                onChange={handleChange}
                type="password"
                required
              ></input>
              <input
                className="w-full h-20 bg-landing-yellow border-2 text-xl border-red font-Inter shadow-lg placeholder:text-rose-500 px-6 mt-4"
                placeholder={t("login.new_pwd") || "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                type="password"
                required
              ></input>
              <input
                className="w-full h-20 bg-landing-yellow border-2 text-xl border-red font-Inter shadow-lg placeholder:text-rose-500 px-6 mt-4"
                placeholder={t("login.confirm_pwd") || "password"}
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                type="password"
                required
              ></input>
              {Object.entries(errors).length > 0 && (
                <ul className="w-full px-2 mt-2 flex flex-col items-end justify-center">
                  {Object.entries(errors).map(([key, value], index) => {
                    if (value)
                      return (
                        <li
                          key={index}
                          className=" text-left text-red text-sm font-bold w-[80%] "
                        >
                          * {value.toString()}
                        </li>
                      );
                    else return null;
                  })}
                </ul>
              )}
              <button
                type="submit"
                className="w-[35%] h-14 bg-light-blue p-2 mt-12 text-xl border-2 font-Inter font-bold border-black shadow-lg shadow-black"
              >
                {t("login.send")}
              </button>
            </form>
          </div>
          <div className="flex flex-col bg-red h-screen w-screen items-end phone:visible web:hidden">
            <div className="w-[90%] h-[35%] mt-[5%] mr-[5%] flex flex-row justify-start">
              <img alt="men" className="h-full" src={propaYell}></img>
            </div>
            <form
              onSubmit={handleSubmit}
              className="w-[100%] h-full flex flex-col justify-between items-center"
            >
              <div className="w-[100%] mt-[10%]  flex flex-col justify-center items-center">
                <input
                  className="w-[90%] h-16 bg-landing-yellow border-2  border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6"
                  placeholder={t("login.pwd_recieved") || "email"}
                  name="reset_password"
                  value={formData.reset_password}
                  onChange={handleChange}
                  type="password"
                  required
                ></input>
                <input
                  className="w-[90%] h-16 bg-landing-yellow border-2  border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-6"
                  placeholder={t("login.new_pwd") || "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  type="password"
                  required
                ></input>
                <input
                  className="w-[90%] h-16 bg-landing-yellow border-2  border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-6"
                  placeholder={t("login.confirm_pwd") || "password"}
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                  type="password"
                  required
                ></input>
                {Object.entries(errors).length > 0 && (
                  <ul className="w-full flex flex-col items-center justify-center">
                    {Object.entries(errors).map(([key, value], index) => {
                      if (value)
                        return (
                          <li
                            key={index}
                            className="text-yellow-500 text-xs font-bold w-[80%] text-left "
                          >
                            * {value.toString()}
                          </li>
                        );
                      else return null;
                    })}
                  </ul>
                )}
              </div>

              <button
                type="submit"
                className="w-[90%] h-16 bg-light-blue p-2 mb-8 border-2 font-Inter text-xl font-bold border-black shadow-lg shadow-black"
              >
                {t("login.send")}
              </button>
            </form>
          </div>
        </div>
      )}
    </Suspense>
  );
}
