import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import arrow from "../../assets/arrowstep.svg";
import arrowB from "../../assets/arrowBlue.svg";
import { PaymentSuccess } from "./PaymentSuccess";
import { PaymentFailure } from "./PaymentFailure";
import axios from "axios";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import Cookies from "js-cookie";
import { Tag, renderTags } from "@axenda/zatca";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { setHasPropaganda } from "../../store/slices/UserSlice";

export function Result() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [qr, setQr] = useState("");
  const location = useLocation();
  const params = useParams();
  const parsed = queryString.parse(location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    const resourcePath = parsed.resourcePath;
    const user = JSON.parse(Cookies.get("user") || "{}");
    const lang = Cookies.get("language") || "en";
    console.log(Cookies.get("method") || "MASTER");
    if (!(params.success === "granted") && loading && !success)
      axios
        .post(process.env.REACT_APP_API_URL + "payment/result", {
          resourcePath: resourcePath,
          user: user,
          language: lang,
          method: Cookies.get("method") || "MASTER",
        })
        .then(async (res) => {
          console.log(res.data.result.code);
          console.log(res.data);
          /*
          /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/
/^(000.400.0[^3]|000.400.100)/
*/
          const successPattern =
            /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/;
          const manuallPattern = /^(000.400.0[^3]|000.400.100)/;

          const match1 = successPattern.test(res.data.result.code);
          const match2 = manuallPattern.test(res.data.result.code);
          if (match1 || match2) {
            setSuccess(true);
          } else setSuccess(false);
          setLoading(false);
        })
        .catch((e) => {
          setSuccess(false);
          setLoading(false);
        });
    if (params.success === "granted") {
      setSuccess(true);
      setLoading(false);
      user.game.push({ name: "propaganda" });
      Cookies.set("user", JSON.stringify(user), {
        secure: false,
        sameSite: "lax",
        path: "/",
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: 30,
      });
      dispatch(setHasPropaganda(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnQr2 = async () => {
    const discount = parseInt(localStorage.getItem("randomValue") || "0");
    let price = 100 - discount;
    let tax = price * 0.15;
    const tags: Tag[] = [
      new Tag(1, "Funny1s Inc"),
      new Tag(2, "3114510871"),
      new Tag(3, format(new Date(), "EEE d MMM yyyy h:mma")),
      new Tag(4, price.toFixed(2)),
      new Tag(5, tax.toFixed(2)),
    ];

    const imageValue = await renderTags(tags);

    return imageValue;
  };

  useEffect(() => {
    returnQr2().then((res) => {
      setQr(res);
    });
  }, []);

  useEffect(() => {
    /*const invoice = new Invoice({
      sellerName: "Funny1s Inc",
      vatRegistrationNumber: "3114510871",
      invoiceTimestamp: new Date().toDateString(),
      invoiceTotal: "100.00",
      invoiceVatTotal: "15.00",
    });*/
    const user = JSON.parse(Cookies.get("user") || "{}");
    const lang = Cookies.get("language") || "en";
    let idd = "";
    if (parsed?.id) idd = parsed.id.toString().split(".")[0];
    if (success && qr && !(params.success === "granted"))
      axios.post(process.env.REACT_APP_API_URL + "payment/sendBill", {
        user: user,
        invoice: qr,
        language: lang,
        date: format(new Date(), "EEE d MMM yyyy h:mma"),
        id: idd,
        price: (
          100 - parseInt(localStorage.getItem("randomValue") || "0")
        ).toFixed(2),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, qr]);

  return (
    <Suspense>
      <div className="w-screen h-screen web:bg-orange phone:bg-red flex flex-col">
        <Helmet>
          <title>Propaganda: Payment result</title>
          <meta name="description" content="Page for payment result."></meta>
        </Helmet>
        <div className="w-[100%] h-[30%] flex flex-col justify-center items-center phone:hidden web:visible">
          <h1 className="w-full text-center text-8xl text-red font-bold font-Inter pt-6 [text-shadow:_0_2px_0_rgb(96_96_96_/_100%)]">
            {t("signin.title")}
          </h1>
          <div className="w-full flex flex-row justify-center items-center pb-4 pt-8">
            <h3
              className={`w-16 h-16 text-center text-5xl text-red  font-bold font-Inter border-2 border-red p-2 rounded-full opacity-50`}
            >
              1
            </h3>
            <img alt="arrow" className="p-4" src={arrow}></img>
            <h3
              className={`w-16 h-16 text-center text-5xl text-red  font-bold font-Inter border-2 border-red p-2 rounded-full opacity-50`}
            >
              2
            </h3>
            <img alt="arrow" className="p-4" src={arrow}></img>
            <h3
              className={`w-16 h-16 text-center text-5xl text-red  font-bold font-Inter border-2 border-red p-2 rounded-full text-100`}
            >
              3
            </h3>
          </div>
        </div>

        <div className="w-[100%] h-[30%] flex flex-col justify-center items-center phone:visible web:hidden">
          <h1 className="w-full text-center text-5xl text-landing-yellow font-bold font-Inter [text-shadow:_0_4px_0_rgb(0_0_0_/_40%)]">
            {t("signin.title")}
          </h1>
          <div className="w-full flex flex-row justify-center items-center mt-8">
            <h3
              className={`w-16 h-16 text-center text-5xl text-light-blue  font-bold font-Inter border-2 border-landing-yellow p-2 rounded-full opacity-50`}
            >
              1
            </h3>
            <img alt="arrow" className="p-4" src={arrowB}></img>
            <h3
              className={`w-16 h-16 text-center text-5xl text-light-blue font-bold font-Inter border-2 border-landing-yellow p-2 rounded-full opacity-50`}
            >
              2
            </h3>
            <img alt="arrow" className="p-4" src={arrowB}></img>
            <h3
              className={`w-16 h-16 text-center text-5xl text-light-blue font-bold font-Inter border-2 border-landing-yellow p-2 rounded-full text-100`}
            >
              3
            </h3>
          </div>
        </div>
        <div className="relative w-[100%] h-[70%] overflow-y-auto">
          {loading && (
            <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center z-30">
              <ReactLoading
                type="spin"
                width={"24px"}
                height={"24px"}
                color="#000"
              ></ReactLoading>
            </div>
          )}
          {success && !loading && <PaymentSuccess></PaymentSuccess>}
          {!success && !loading && <PaymentFailure></PaymentFailure>}
        </div>
      </div>
    </Suspense>
  );
}
