import { configureStore } from "@reduxjs/toolkit";
import { Api } from "./Apis/Api";
import CycleQuestionSlice from "./slices/CycleQuestionSlice";
import MainScreenSlice from "./slices/MainScreenSlice";
import PlayerSlice from "./slices/PlayerSlice";
import UserSlice from "./slices/UserSlice";

const store = configureStore({
    reducer: { api: Api.reducer, mainScreen: MainScreenSlice, player: PlayerSlice, cycle: CycleQuestionSlice, user: UserSlice },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(Api.middleware),
    devTools: true
})
export default store;
export type RootState = ReturnType<typeof store.getState>