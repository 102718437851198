import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import arrow from "../assets/arrowstep.svg";
import arrowB from "../assets/arrowBlue.svg";
import { Payment } from "../components/Authentication/Payment";
import { PaymentSuccess } from "../components/Authentication/PaymentSuccess";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import { BillingInfo } from "../components/Authentication/BillingInfo";

export function Purshase() {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const language = Cookies.get("language") ? Cookies.get("language") : "ar";
  //const dispatch = useDispatch();
  const authenticated = useSelector(
    (state: RootState) => state.user.authenticated
  );
  //const user = useSelector((state: RootState) => state.user.user)
  const hasPropaganda = useSelector(
    (state: RootState) => state.user.hasPropaganda
  );

  useEffect(() => {
    if (authenticated && hasPropaganda) {
      navigate("/");
    } else {
      if (!authenticated) {
        navigate("/register");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goNext = () => {
    if (step < 3) setStep(step + 1);
  };

  return (
    <Suspense>
      <Helmet>
        <title>Propaganda: Purshase Propaganda</title>
        <meta name="description" content="Purshase the game page."></meta>
      </Helmet>
      <div className="w-screen h-screen web:bg-orange phone:bg-red flex flex-col">
        <div className="w-[100%] h-[30%] flex pb-2 flex-col justify-center items-center phone:hidden web:visible">
          <h1 className="w-full text-center text-8xl text-red font-bold font-Inter pt-6 [text-shadow:_0_2px_0_rgb(96_96_96_/_100%)]">
            {t("signin.title")}
          </h1>
          <div className="w-full flex flex-row justify-center items-center mt-8  web:pb-2">
            <h3
              className={`w-16 h-16 text-center text-5xl text-red  font-bold font-Inter border-2 border-red p-2 rounded-full ${
                step === 1 ? "text-100" : "opacity-50"
              }`}
            >
              1
            </h3>
            <img
              alt="arrow"
              className={`p-4 ${
                language === "ar" ? "" : "transform -scale-x-100"
              }`}
              src={arrow}
            ></img>
            <h3
              className={`w-16 h-16 text-center text-5xl text-red  font-bold font-Inter border-2 border-red p-2 rounded-full ${
                step === 2 ? "text-100" : "opacity-50"
              }`}
            >
              2
            </h3>
            <img
              alt="arrow"
              className={`p-4 ${
                language === "ar" ? "" : "transform -scale-x-100"
              }`}
              src={arrow}
            ></img>
            <h3
              className={`w-16 h-16 text-center text-5xl text-red  font-bold font-Inter border-2 border-red p-2 rounded-full ${
                step === 3 ? "text-100" : "opacity-50"
              }`}
            >
              3
            </h3>
          </div>
        </div>

        <div className="w-[100%] h-[30%] flex flex-col justify-center items-center phone:visible web:hidden">
          <h1 className="w-full text-center text-5xl text-landing-yellow font-bold font-Inter [text-shadow:_0_4px_0_rgb(0_0_0_/_40%)]">
            {t("signin.title")}
          </h1>
          <div className="w-full flex flex-row justify-center items-center mt-8">
            <h3
              className={`w-16 h-16 text-center text-5xl text-light-blue  font-bold font-Inter border-2 border-landing-yellow p-2 rounded-full ${
                step === 1 ? "text-100" : "opacity-50"
              }`}
            >
              1
            </h3>
            <img alt="arrow" className="p-4" src={arrowB}></img>
            <h3
              className={`w-16 h-16 text-center text-5xl text-light-blue font-bold font-Inter border-2 border-landing-yellow p-2 rounded-full ${
                step === 2 ? "text-100" : "opacity-50"
              }`}
            >
              2
            </h3>
            <img alt="arrow" className="p-4" src={arrowB}></img>
            <h3
              className={`w-16 h-16 text-center text-5xl text-light-blue font-bold font-Inter border-2 border-landing-yellow p-2 rounded-full ${
                step === 3 ? "text-100" : "opacity-50"
              }`}
            >
              3
            </h3>
          </div>
        </div>
        <div className="w-[100%] h-[70%] mb-6 overflow-hidden">
          {step === 1 && <BillingInfo next={goNext}></BillingInfo>}
          {step === 2 && <Payment next={goNext}></Payment>}
          {step === 3 && <PaymentSuccess></PaymentSuccess>}
        </div>
      </div>
    </Suspense>
  );
}
