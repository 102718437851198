import React, { useEffect, useState } from "react";
import { Document, Page,pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";

export function TermsModal(props: { setShowModal: any, params: any }) {

    const ressources = useSelector((state: RootState) => state.mainScreen.ressources);
    const [url,setUrl]= useState("");
    const [numPages, setNumPages] = useState(1);
    const [scale, setScale] = useState(1);
    const { i18n } = useTranslation();

  function onDocumentLoadSuccess( pdf: {
      [x: string]: any; 
}) {
    setNumPages(pdf.numPages);
    const screenWidth = document.getElementById("PdfCont")?.offsetWidth||600;
    const scale = screenWidth / 600;
    setScale(scale);
  }
  
    useEffect(()=>{
        pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const pdf : {[key: string]: string}=ressources['pdf'];
    const language = i18n.language;
    const y = 'terms-'+ language+'.pdf';
    setUrl(pdf[y])     
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (<div className="fixed flex items-center justify-center  w-screen h-screen inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-50">
        <div className="relative h-[90%] w-[90%] bg-white pl-4 pr-8 py-8 rounded-lg shadow-xl">
            <h3 onClick={() => props.setShowModal()}
                className="absolute top-0 right-0 px-4 py-1 web:text-3xl phone:text-xl text-gray-400 cursor-pointer">x</h3>
            <div id="PdfCont" className="h-full w-full  overflow-y-scroll">
                {!props.params && <Document className="w-full"
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page className="w-full" key={`page_${index + 1}`} pageNumber={index + 1} renderTextLayer={false} scale={scale} renderAnnotationLayer={false}/>
      ))}
    </Document> }
                {props.params && <h3>
                    {props.params}
                </h3>}
            </div>
        </div>
    </div>)

}