import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import {
  setCurrentPage,
  setRoundNumber,
  setResponses,
  setQuestion,
  setPlayersNumber,
  setCode,
} from "../store/slices/PlayerSlice";
import { SocketContext } from "../context/socket";
import { Join } from "../components/Playerscreen/Join";
import { Waiting } from "../components/Playerscreen/Waiting";
import { Lie } from "../components/Playerscreen/Lie";
import { Round } from "../components/Playerscreen/Round";
import { Respond } from "../components/Playerscreen/Respond";
import { Over } from "../components/Playerscreen/Over";
import { Skip } from "../components/Playerscreen/Skip";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

export function PlayerScreen() {
  const currentPage = useSelector(
    (state: RootState) => state.player.currentPage
  );
  const vip = useSelector((state: RootState) => state.player.vip);
  const room = useSelector((state: RootState) => state.player.code);
  const name = useSelector((state: RootState) => state.player.playerName);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [leave, setLeave] = useState(false);
  const [connectionLost, setConnectionLost] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("ar");
    document.body.dir = "rtl";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.off("ROUND");
    socket.off("ENTER_LIES");
    socket.off("ENTER_RESPONSES");
    socket.off("PLAYER_JOINED");
    socket.off("GAME_OVER");
    socket.off("NEW_GAME");
    socket.off("PLAY_AGAIN");
    socket.off("EXIT_GAME");
    socket.off("disconnect");
    socket.off("REJOIN");

    socket.on("disconnect", (reason) => {
      setConnectionLost(true);
    });

    socket.on("ROUND", (data) => {
      dispatch(setRoundNumber(data.number));
      if (vip && data.number === 1) {
        dispatch(setCurrentPage("skip"));
        setTimeout(() => {
          dispatch(setCurrentPage("round"));
        }, 14500);
      } else dispatch(setCurrentPage("round"));
    });
    socket.on("ENTER_LIES", (data) => {
      dispatch(setQuestion(data.question.question));
      dispatch(setCurrentPage("lie"));
    });
    socket.on("ENTER_RESPONSES", (data) => {
      dispatch(setResponses(data.suggestions));
      dispatch(setCurrentPage("respond"));
    });
    socket.on("PLAYER_JOINED", (data) => {
      dispatch(setPlayersNumber(data));
    });
    socket.on("GAME_OVER", (data) => {
      if (vip) dispatch(setCurrentPage("over"));
    });
    socket.on("NEW_GAME", (data) => {
      if (!vip) {
        dispatch(setCurrentPage("join"));
        setLeave(true);
      }
    });
    socket.on("PLAY_AGAIN", (data) => {
      dispatch(setCurrentPage("waiting"));
    });
    socket.on("EXIT_GAME", (data) => {
      dispatch(setCurrentPage("join"));
      setLeave(true);
    });
    socket.on("REJOIN", (data) => {
      dispatch(setPlayersNumber(data.players));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, vip]);

  useEffect(() => {
    if (leave) {
      socket.emit("LEAVE_ROOM", { roomId: room, name: name });
      dispatch(setCode(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leave]);

  useEffect(() => {
    if (connectionLost) {
      socket.off("connect");

      socket.on("connect", () => {
        // ...
        setConnectionLost(false);
        socket.emit("REJOIN", { name: name, roomId: room });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionLost]);

  return (
    <div className="font-Inter">
      <Helmet>
        <title>Propaganda: In game</title>
        <meta
          name="description"
          content="This the player in game screen."
        ></meta>
      </Helmet>
      {connectionLost && (
        <div className="fixed flex items-center justify-center  w-screen h-screen inset-0 z-50 overflow-y-auto bg-gray-900 bg-opacity-50">
          <div className="relative h-[30%] w-[50%] flex items-center justify-center bg-white pl-4 pr-8 py-8 rounded-lg shadow-xl">
            <h3 className="text-3xl text-center">
              {t("errors.LOST_INTERNET")}
            </h3>
          </div>
        </div>
      )}
      {currentPage === "join" && <Join></Join>}
      {currentPage === "skip" && <Skip></Skip>}
      {currentPage === "waiting" && <Waiting></Waiting>}
      {currentPage === "lie" && <Lie></Lie>}
      {currentPage === "round" && <Round></Round>}
      {currentPage === "respond" && <Respond></Respond>}
      {currentPage === "over" && <Over></Over>}
    </div>
  );
}
