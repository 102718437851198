import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import propaRed from "../../assets/porpa.svg";
import propaRev from "../../assets/propa-reversed.svg";
import { useRegisterMutation } from "../../store/Apis/UserApi";
import { setAuthenticated } from "../../store/slices/UserSlice";
import { RootState } from "../../store/store";
import "./SignForm.css";
import { TermsModal } from "./TermsModal";

export function SignForm(props: { next: any }) {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    familyName: "",
    password: "",
    confirm_password: "",
    language: "ar",
  });
  const [errors, setErrors] = useState({});
  const [margin, setMargin] = useState("ml");
  const [register] = useRegisterMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authenticated = useSelector(
    (state: RootState) => state.user.authenticated
  );
  const [termsAgreed, setTermsAgreed] = useState(false);
  //const user = useSelector((state: RootState) => state.user.user)
  const hasPropaganda = useSelector(
    (state: RootState) => state.user.hasPropaganda
  );
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (authenticated && hasPropaganda) {
      navigate("/");
    } else {
      if (authenticated) {
        props.next();
      }
    }
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (i18n.language !== "ar") {
      setMargin("mr");
      setFormData({ ...formData, language: "en" });
    }
  });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // Form can be submitted
      register(formData)
        .unwrap()
        .then((data) => {
          dispatch(setAuthenticated(data));
          props.next();
        })
        .catch((err) => {
          if (err.data.error_code)
            setErrors({
              error: t("errors." + err.data.error_code) || err.data.error,
            });
          else setErrors({ error: err.data.error });
        });
    } else {
      setErrors(validationErrors);
    }
  };

  const validateInput = (name: string, value: string) => {
    switch (name) {
      case "phone":
        return /^(\+\d{1,2}\s?)?\d{8,}$/.test(value)
          ? null
          : t("errors.PHONE_REQUIRED") || "Please enter a valid phone";
      case "password":
        return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
          value
        )
          ? null
          : t("errors.WEAK_PWD") ||
              "password at least 8 characters with at least 1 number and 1 symbol";
      case "confirm_password":
        return value === formData.password
          ? null
          : t("errors.NOT_MATCHING_PWD") || "passwords not matching";
      default:
        return null;
    }
  };

  const validateForm = () => {
    const validationErrors: any = {};
    for (const [name, value] of Object.entries(formData)) {
      const err = validateInput(name, value);
      if (err) validationErrors[name] = err;
    }
    if (!termsAgreed)
      validationErrors["terms"] = "Please agree to the terms and conditions.";
    return validationErrors;
  };

  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    //setErrors({ ...errors, [event.target.name]: validateInput(event.target.name, event.target.value) });
  };
  const handleTermsChange = (event: any) => {
    setTermsAgreed(event.target.checked);
  };

  return (
    <Suspense>
      {showModal && (
        <TermsModal
          params={""}
          setShowModal={() => setShowModal(false)}
        ></TermsModal>
      )}

      <div className="w-full h-full flex flex-row">
        <div className="w-[20%] h-full flex items-end justify-end">
          <img alt="retro" className="h-[55%] mb-[20%]" src={propaRed}></img>
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-[60%] h-full flex flex-col bg-orange items-end justify-center phone:hidden web:visible"
        >
          <div className="w-full flex flex-col items-center justify-center gap-4">
          <h2 className="font-Inter font-bold text-3xl">{t("signin.sign")}</h2>
            <div className="w-[80%] flex flex-row gap-4">
              <input
                className="w-[50%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.name") || "name"}
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                min="3"
                required
              ></input>
              <input
                className="w-[50%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.family") || "family name"}
                type="text"
                name="familyName"
                value={formData.familyName}
                onChange={handleChange}
                min="3"
                required
              ></input>
            </div>
            <div className="w-[80%] flex flex-row gap-4">
              <input
                className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.email") || "email"}
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              ></input>
            </div>
            <div className="w-[80%] flex flex-row gap-4">
              <input
                className="w-[50%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.pwd") || "password"}
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              ></input>
              <input
                className="w-[50%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg placeholder:text-rose-500 px-4"
                placeholder={t("signin.confirm_pwd") || "confirm password"}
                type="password"
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                required
              ></input>
            </div>

            <div className="w-[80%] flex flex-row items-center px-2 py-2">
              <input
                type="checkbox"
                name="terms"
                checked={termsAgreed}
                onChange={handleTermsChange}
              ></input>
              <h3 className=" ps-3 text-lg text-black font-Inter font-bold mt-1 ">
                {t("signin.terms1")}
              </h3>
              <h3
                className="ps-1 text-lg text-black font-Inter font-bold mt-1 underline cursor-pointer"
                onClick={() => setShowModal(true)}
              >
                {t("signin.terms2")}
              </h3>
            </div>
          </div>
          {Object.entries(errors).length > 0 && (
            <ul className="w-full flex flex-col items-center justify-center">
              {Object.entries(errors).map(([key, value], index) => {
                if (value)
                  return (
                    <li
                      key={index}
                      className="web:text-red phone:text-yellow-400 text-sm font-bold w-[80%] "
                    >
                      {value.toString()}
                    </li>
                  );
                else return null;
              })}
            </ul>
          )}
          <button
            className={`w-[30%] ${margin}-[10%] h-12 bg-light-blue p-2 mt-4 border-2 font-Inter font-bold border-black shadow-lg`}
            type="submit"
          >
            {t("signin.subscribe")}
          </button>
        </form>
        <form
          onSubmit={handleSubmit}
          className="flex overflow-y-auto pb-8 flex-col justify-between w-full h-full bg-red items-center web:hidden phone:visible"
        >
          <h2 className="font-Inter font-bold text-xl">{t("signin.sign")}</h2>
          <div className="w-full flex flex-col items-center justify-center">
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 "
              placeholder={t("signin.name") || "name"}
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            ></input>
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              placeholder={t("signin.family") || "family name"}
              type="text"
              name="familyName"
              value={formData.familyName}
              onChange={handleChange}
            ></input>
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              placeholder={t("signin.email") || "email"}
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            ></input>
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              placeholder={t("signin.pwd") || "password"}
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            ></input>
            <input
              className="w-[100%] h-12 bg-landing-yellow border-2 border-red font-Inter shadow-lg shadow-black placeholder:text-rose-500 px-6 mt-4"
              placeholder={t("signin.confirm_pwd") || "confirm password"}
              type="password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
            ></input>
            <div className="w-[100%] flex flex-row items-center px-2 pt-4">
              <input
                type="checkbox"
                checked={termsAgreed}
                onChange={handleTermsChange}
              ></input>
              <h3 className="ps-2 font-bold text-lg text-black font-Inter">
                {t("signin.terms1")}
              </h3>
              <h3 className="ps-1 font-bold text-lg text-black font-Inter underline cursor-pointer">
                {t("signin.terms2")}
              </h3>
            </div>
          </div>
          {Object.entries(errors).length > 0 && (
            <ul className="w-full flex flex-col items-start justify-center">
              {Object.entries(errors).map(([key, value], index) => {
                if (value)
                  return (
                    <li
                      key={index}
                      className="web:text-red phone:text-yellow-400 text-sm w-[80%] "
                    >
                      {value.toString()}
                    </li>
                  );
                else return null;
              })}
            </ul>
          )}
          <input
            className="w-[100%] h-12 bg-light-blue p-2 mt-4 border-2 font-Inter font-bold border-black shadow-lg z-50"
            type="submit"
            value={t("signin.subscribe") || "Sign in"}
          />
        </form>
        <div className="w-[20%] h-full flex items-end justify-start">
          <img
            alt="reversed retro"
            className="h-[55%] mb-[20%]"
            src={propaRev}
          ></img>
        </div>
      </div>
    </Suspense>
  );
}
