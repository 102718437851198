import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../context/socket";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

export function FeedbackModal(props: { setShowModal: any }) {
    const { t } = useTranslation();
    const socket = useContext(SocketContext)
    const [message, setMessage] = useState("")
    const user = useSelector((state: RootState) => state.user.user)

    return (<div className="fixed flex items-center justify-center w-screen h-screen inset-0 z-50 bg-gray-900 bg-opacity-50">
        <div className="relative h-[65%] w-[45%] phone:h-[90%] phone:w-[90%] bg-red px-4 py-8 rounded-lg shadow-xl">
            <h3 onClick={() => props.setShowModal()}
                className="absolute top-0 left-0 px-4 py-1 web:text-3xl phone:text-xl text-black cursor-pointer">x</h3>
            <form onSubmit={() => { socket.emit("FEEDBACK", { message: message, from: user.email, name: user.familyName + " " + user.name }) }} className="flex flex-col h-full w-[95%] mt-4 mx-4 items-center overflow-y-auto">
                <h2 className="w-full text-center font-bold text-2xl">{t('feedback.title')}</h2>
                <div className="w-full h-[60%] mt-8 shadow-xl shadow-slate-800 bg-landing-yellow">
                    <textarea className="w-full h-[100%] pt-3 bg-landing-yellow px-2" placeholder={t('feedback.message') || "email"} value={message} onChange={(event: any) => { setMessage(event.target.value) }} required></textarea>
                </div>
                <button type="submit" className="web:p-4 web:px-16 p-4 px-12 web:mb-2 mt-6  bg-orange web:shadow-md shadow-sm shadow-slate-700 border-2 border-black text-Inter font-bold"  > {t("feedback.submit")}</button>
            </form>
        </div>
    </div>)

}