
import React from "react"
import { useTranslation } from "react-i18next";
import propa from "../../assets/porpa.svg"
import { Suspense } from "react";



export function About() {
    const { t } = useTranslation();

    return (
        <Suspense>
            <div className="flex flex-col w-full h-[90vh] bg-cover bg-landing-yellow justify-center items-center content-center" id="about">
                <h1 className=" w-[100%] text-center web:top-[15vh] top-2 web:text-9xl text-4xl web:font-normal font-semibold font-Inter text-red " > {t('about.title')}</h1>
                <div className="flex web:flex-row flex-col justify-center items-center content-center" >
                    <img alt="propaganda" className="basis-1/3 web:h-[80%] w-48 mx-[10%] web:order-1 order-2 " src={propa}></img>
                    <div className="grow flex flex-col basis-2/3 web:order-2 order-1 items-center" >
                        <h5 className="w-[70%] web:text-4xl text-xl font-Inter text-black font-light">{t("about.desc")}</h5>
                        <h5 className="w-[70%] web:mt-16 mt-4 web:mb-0 mb-4 underline web:text-4xl text-2xl font-Inter text-black font-light">{t("about.url")}</h5>
                    </div>
                </div>
            </div>
        </Suspense>
    )
}