import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../context/socket";
import {
  setCode,
  setCurrentPage,
  setPlayerName,
  setPlayersNumber,
  setVip,
  setGameState,
} from "../../store/slices/PlayerSlice";
import propagandaPlayer from "../../assets/propaganda-player.svg";
import qr from "../../assets/qr.svg";
import { useParams } from "react-router-dom";
import "./join.css";
import { Helmet } from "react-helmet";

export function Join() {
  const params = useParams();
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const [roomId, setRoomId] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const [h, setH] = useState({});

  useEffect(() => {
    if (params.room) setRoomId(params.room);
    if (window.innerWidth < 1024) setH({ height: window.innerHeight });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const joinGame = () => {
    if (!name) {
      setError(t("join.error_name") || "name is required");
    } else {
      let data: IData = {};
      data.roomId = roomId;
      data.name = name;
      socket.emit("joinLobby", data);
    }
  };

  useEffect(() => {
    if (socket) {
      socket.off("JOIN_ERROR");
      socket.off("JOIN_SUCCESS");
      socket.off("REJOIN_SUCCESS");

      socket.on("JOIN_ERROR", (data) => {
        switch (data.type) {
          case "name":
            setError(t("errors.JOIN_ERROR_NAME") || "* Name already exists");
            break;
          case "room":
            setError(t("errors.JOIN_ERROR_ROOM") || "* No such room");
            break;
          case "old":
            setError(t("errors.OLD_USER") || "* Old user is still connected");
            break;
          default:
            setError("");
            break;
        }
      });
      socket.on("JOIN_SUCCESS", (data) => {
        setError("");
        dispatch(setVip(data));
        dispatch(setCode(data.roomId));
        dispatch(setGameState(data.state));
        dispatch(setCurrentPage("waiting"));
      });

      socket.on("REJOIN_SUCCESS", (data) => {
        setError("");
        dispatch(setVip(data.p));
        dispatch(setCode(data.p.roomId));
        dispatch(setPlayersNumber(data.number));
        dispatch(setGameState(data.state));
        dispatch(setCurrentPage("waiting"));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  interface IData {
    name?: string;
    roomId?: string;
  }

  const handleChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z]/gi, "");
    setRoomId(value.toLocaleUpperCase());
  };

  return (
    <div
      className="absolute w-screen  bg-join-background bg-cover z-0 h-screen desktop:overflow-y-auto phone:overflow-y-hidden brightness-110"
      style={h}
    >
      <Helmet>
        <title>Propaganda: Join game</title>
        <meta name="description" content="Joining a propaganda session page."></meta>
      </Helmet>
      <div className="relative top-0 px-[15%] desktop:mt-4 mt-2 flex justify-between z-30">
        <img
          alt="propaganda logo"
          className="shrink"
          src={propagandaPlayer}
        ></img>
        <img alt="qr code" className="shrink" src={qr}></img>
      </div>
      <div
        className="w-[90%] mx-[5%] h-fit items-center flex flex-col z-30"
        style={h}
      >
        <h5 className="h5 text-darker-blue font-semibold xs:text-lg text-3xl desktop:text-5xl font-Inter z-30 phone:mt-4 mt-8">
          {t("join.1")}
        </h5>
        <h5 className="h5 text-darker-blue font-semibold xs:text-lg text-3xl desktop:text-5xl font-Inter z-30">
          {t("join.2")}
        </h5>
        <h5 className="h5 text-darker-blue font-semibold xs:text-lg text-3xl desktop:text-5xl font-Inter z-30">
          {t("join.3")}
        </h5>
        <input
          type="text"
          className=" w-[85%] desktop:mt-4 phone:mt-2 desktop:text-5xl desktop:w-[50%] h-16 opacity-80 rounded-lg text-dark-blue text-xl border-2 border-darker-blue z-30"
          maxLength={5}
          value={roomId}
          onChange={handleChange}
        ></input>
        <h5 className="h5 text-darker-blue font-semibold xs:text-lg desktop:text-5xl text-3xl font-Inter xs:py-2 py-4 phone:py-2 z-30">
          {t("join.name")}
        </h5>
        <input
          type="text"
          className=" w-[85%] desktop:text-5xl desktop:w-[50%] h-16 opacity-80 rounded-lg text-dark-blue text-xl border-2 border-darker-blue z-30"
          maxLength={16}
          onChange={(e) => {
            setName(e.target.value);
            dispatch(setPlayerName(e.target.value));
          }}
        ></input>
        {error && (
          <h2 className=" text-red xs:text-lg desktop:text-3xl text-2xl font-Inter font-bold py-2 z-50">
            {error}
          </h2>
        )}
        <button
          className="h-28 w-28 desktop:h-48 desktop:w-48 desktop:text-5xl desktop:mt-16 bg-btn-red text-darker-blue text-3xl font-Cairo rounded-full phone:mt-10 border-2 border-dark-blue z-30"
          onClick={() => joinGame()}
        >
          {t("join.enter")}
        </button>
      </div>
    </div>
  );
}
