import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { isExpired } from "react-jwt";
import { logout } from "../store/slices/UserSlice";
import Cookies from 'js-cookie';

const token = Cookies.get('token');

function UnauthRoute(props: any) {
    const dispatch = useDispatch();

    const redirect = () => {
        if (isExpired(token ? token : "")) {
            return (props.child)
        }
        else {
            dispatch(logout({}));
            return (<Navigate to="/landing" />)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    return redirect()
}

export default UnauthRoute;