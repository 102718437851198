import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../context/socket";
import {
  setCurrentPage,
  setFirstRound,
  setQuestionNumber,
  setQuestions,
  setRoundNumber,
} from "../../store/slices/MainScreenSlice";
import {
  setQuestionPage,
  SetShowRound,
} from "../../store/slices/CycleQuestionSlice";
import tv from "../../assets/tv.svg";
import vip from "../../assets/vip.svg";
import { PlayerContext } from "../../context/howler";
import { QRCodeSVG } from "qrcode.react";
import Ticker from "react-ticker";
import settings from "../../assets/settings.png";
import { HowlerPlayer } from "../../context/HowlerPlayer";
import { setFamilyFriendly } from "../../store/slices/UserSlice";

export function Lobby() {
  const { t } = useTranslation();
  const players = useSelector((state: RootState) => state.mainScreen.players);
  const code = useSelector((state: RootState) => state.mainScreen.roomCode);
  const familyFriendly = useSelector((state: RootState) => state.user.familyFriendly);
  const [show, setShow] = useState(0);
  const [hideCode, setHideCode] = useState(false);
  const [link, setLink] = useState("");
  const socket = useContext(SocketContext);
  const howler = useContext(PlayerContext);
  const [showSettings, setShowSettings] = useState(false);
  const [narVol, setNarVol] = useState(HowlerPlayer.narratorVolume);
  const [sfxVolume, setSfxVolume] = useState(HowlerPlayer.sfxVolume);
  const [musicVolume, setMusicVolume] = useState(HowlerPlayer.musicVolume);
  const dispatch = useDispatch();

  const getPlayers = () => {
    return players.length;
  };

  useEffect(() => {
    let url = window.location.href;
    url = url.replace("game", "join/" + code);
    setLink(url);
    dispatch(setQuestionPage("category"));
    socket.off("QUESTIONS_RES");
    socket.on("QUESTIONS_RES", (data) => {
      console.log("questions: ",data);
      if (data.length === 7) dispatch(setFirstRound(3));
      else dispatch(setFirstRound(4));

      dispatch(setQuestions(data));
      dispatch(SetShowRound(true));
      dispatch(setRoundNumber(1));
      dispatch(setQuestionNumber(0));
      setShow(1);
      setTimeout(() => {
        dispatch(setCurrentPage("cycle"));
      }, 18000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  useEffect(() => {
    if (show === 1) {
      howler.stop();
      howler.playListIntro(getPlayers());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    socket.emit("FRIENDLY", { room: code });
    console.log("FRIENDLY", familyFriendly);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyFriendly]);

  return (
    <div className="relative w-screen h-screen bg-orange">
  
      {showSettings && (
        <div className="absolute top-0 w-full h-full z-50 bg-transparent backdrop-blur-sm flex items-center justify-center">
          <div className=" relative p-12 bg-white flex flex-col items-center justify-start rounded-2xl  border-black border-2">
            <h3
              className="absolute top-0 left-0 text-xl text-black cursor-pointer p-2"
              onClick={() => {
                setShowSettings(false);
              }}
            >
              X
            </h3>
            <h3 className="text-4xl text-dark-blue underline mb-8">
              {t("lobby.voice_settings")}
            </h3>
            <div className="w-full flex flex-col items-center justify-center gap-4">
              <div className="w-full flex flex-col items-center justify-start gap-4">
                <div className="w-full flex flex-row items-center justify-between gap-8">
                  <h3 className="text-2xl text-dark-blue underline">
                    {t("lobby.narrator")}
                  </h3>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={narVol}
                    onChange={(e) => {
                      setNarVol(parseFloat(e.target.value));
                      HowlerPlayer.narratorVolume = parseFloat(e.target.value);
                      localStorage.setItem("narratorVolume", e.target.value);
                    }}
                  ></input>
                </div>
                <div className="w-full flex flex-row items-center justify-between gap-8">
                  <h3 className="text-2xl text-dark-blue underline">
                    {t("lobby.sfx")}
                  </h3>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={sfxVolume}
                    onChange={(e) => {
                      setSfxVolume(parseFloat(e.target.value));
                      HowlerPlayer.sfxVolume = parseFloat(e.target.value);
                      localStorage.setItem("sfxVolume", e.target.value);
                    }}
                  ></input>
                </div>
                <div className="w-full flex flex-row items-center justify-between gap-8">
                  <h3 className="text-2xl text-dark-blue underline">
                    {t("lobby.bg")}
                  </h3>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    value={musicVolume}
                    onChange={(e) => {
                      howler.player.volume(parseFloat(e.target.value));
                      setMusicVolume(parseFloat(e.target.value));
                      HowlerPlayer.musicVolume = parseFloat(e.target.value);
                      localStorage.setItem("musicVolume", e.target.value);
                    }}
                  ></input>
                </div>
                <div className="w-full flex flex-row items-center justify-between gap-8">
                  <h3 className="text-2xl text-dark-blue underline">
                    {t("lobby.family_filter")}
                  </h3>
                  <input
                    type="checkbox"
                    checked={familyFriendly}
                    onChange={(e) => {
                     dispatch(setFamilyFriendly(e.target.checked));
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {show === 0 && (
        <div className="w-screen h-screen phone:hidden">
          <div className="flex justify-between pt-2 h-20">
            <h3 className="text-5xl text-dark-blue mr-16 ">
              {t("lobby.lounge")}
            </h3>
            <div className="flex flex-row items-center justify-center px-4">
            <img
              className="w-10 h-10 p-2 phone:hidden z-40 cursor-pointer"
              src={settings}
              alt="voice settings"
              onClick={() => {
              setShowSettings(true);
            }}
            ></img>
            <h3
              className="text-xl underline text-dark-blue p-2 px-2 hover:cursor-pointer"
              onClick={() => {
                window.location.reload();
              }}
            >
              {t("lobby.exit")}
            </h3>
            </div>
          </div>
          <div className="flex row justify-start pt-16">
            <h3 className="w-[60%] text-7xl text-dark-blue mr-16">
              {t("lobby.how_to")}
            </h3>
            <h3 className="grow text-7xl text-dark-blue ml-16">
              {t("lobby.players")}
            </h3>
          </div>
          <div className="flex row justify-start pt-16">
            <h3 className="w-1/2 w-max[50%] text-3xl text-dark-blue mx-20">
              {t("lobby.how_to_details")}
            </h3>
            <div className="w-1/2 w-max[50%] grid grid-cols-2 gap-4 justify-center">
              {players &&
                players.map((player, index) => {
                  if (index % 2 === 0)
                    return (
                      <button
                        key={player.name}
                        className="relative h-max-[100px] justify-self-end w-max-[300px] w-72 h-24 text-2xl text-center bg-dark-blue text-beige rounded-xl"
                      >
                        {player.vip && (
                          <div className="absolute left-1 top-0 flex flex-col h-[100%] items-center justify-center">
                            <img alt="vip" src={vip}></img>
                          </div>
                        )}
                        {player.name}
                      </button>
                    );
                  return (
                    <button
                      key={player.name}
                      className="relative h-max-[100px] justify-self-start w-max-[300px] w-72 h-24 text-2xl text-center bg-dark-blue text-beige rounded-xl"
                    >
                      {player.vip && (
                        <div className="absolute left-1 top-0 flex flex-col h-[100%] items-center justify-center">
                          <img alt="vip" src={vip}></img>
                        </div>
                      )}
                      {player.name}
                    </button>
                  );
                })}
            </div>
          </div>
          <div className="w-[99%] absolute flex flex-row items-end bottom-6 start-4">
            <div
              className={`relative flex flex-col items-center justify-center border border-black w-56 h-56 bg-white me-4 ${
                hideCode ? "hidden" : ""
              }`}
            >
              <h5
                className="absolute w-7 h-7 text-center text-sm text-white border-2 font-bold border-white p-1 rounded-full top-[-36px] start-0 cursor-pointer"
                onClick={() => {
                  setHideCode(true);
                }}
              >
                X
              </h5>
              <h5 className="shrink text-3xl text-black border-b border-black py-4">
                {t("lobby.code")}
              </h5>
              <h5 className="shrink text-3xl text-black py-4">{code}</h5>
            </div>
            <div
              className={`flex flex-col items-center justify-center border border-black w-56 h-56 bg-white ${
                hideCode ? "hidden" : ""
              }`}
            >
              <h5 className="shrink text-3xl text-black py-4">
                <QRCodeSVG size={200} value={link} />
              </h5>
            </div>
            {hideCode && (
              <h5
                className="absolute w-7 h-7 text-center text-sm text-white border-2 font-bold border-white p-1 rounded-full bottom-4 start-4 end-0 cursor-pointer"
                onClick={() => {
                  setHideCode(false);
                }}
              >
                ^
              </h5>
            )}
            <div className={`${hideCode ? "ms-64" : "ms-4"}`}>
              {/*<Tv></Tv>*/}
              <img alt="tv" className="h-72" src={tv}></img>
            </div>
            <div className="w-[50%] grow ms-2">
              <Ticker direction="toRight">
                {({ index }) => (
                  <>
                    <div className="flex flex-row gap-4 px-8">
                      <h1 className="text-4xl text-rose-700 font-Algerian font-boldl">
                        {" "}
                        {t("lobby.breaking")}{" "}
                      </h1>
                      <h1 className="text-4xl text-dark-blue font-Algerian font-normal">
                        {" "}
                        {t("lobby.joinus")}{" "}
                      </h1>
                    </div>
                  </>
                )}
              </Ticker>
            </div>
          </div>
        </div>
      )}

      {show === 1 && (
        <div className="w-screen h-screen phone:hidden">
          <div className="flex justify-end pt-2 h-20">
          <div className="flex flex-row items-center justify-center">
            <img
              className="w-10 h-10 p-2 phone:hidden z-40 cursor-pointer"
              src={settings}
              alt="voice settings"
              onClick={() => {
              setShowSettings(true);
            }}
            ></img>
            <h3
              className="text-xl underline text-dark-blue px-2 p-2 hover:cursor-pointer"
              onClick={() => {
                window.location.reload();
              }}
            >
              {t("lobby.exit")}
            </h3>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h3 className="grow text-8xl text-dark-blue mt-16 font-Cairo text-center">
              {t("lobby.players")}
            </h3>
            <div
              className={`shrink w-[${
                players.length < 4 ? 50 : 85
              }%]  justify-items-center mt-20 gap-2 grid ${
                players.length < 4
                  ? "grid-cols-" + players.length
                  : "grid-cols-5"
              }`}
            >
              {players &&
                players.map((player, index) => {
                  return (
                    <button
                      key={player.name}
                      className="relative h-max-[100px] justify-self-center w-max-[300px] w-64 h-24 text-2xl text-center bg-dark-blue text-beige rounded-xl"
                    >
                      {player.vip && (
                        <div className="absolute left-1 top-0 flex flex-col h-[100%] items-center justify-center">
                          <img alt="vip" src={vip}></img>
                        </div>
                      )}
                      {player.name}
                    </button>
                  );
                })}
            </div>
          </div>
          <div className="absolute flex flex-row items-end bottom-6 start-4">
            <div
              className={`relative flex flex-col items-center justify-center border border-black w-56 h-56 bg-white me-4 ${
                hideCode ? "hidden" : ""
              }`}
            >
              <h5
                className="absolute w-7 h-7 text-center text-sm text-white border-2 font-bold border-white p-1 rounded-full top-[-36px] start-0 cursor-pointer"
                onClick={() => {
                  setHideCode(true);
                }}
              >
                X
              </h5>
              <h5 className="shrink text-3xl text-black border-b border-black py-4">
                {t("lobby.code")}
              </h5>
              <h5 className="shrink text-3xl text-black py-4">{code}</h5>
            </div>
            <div
              className={`flex flex-col items-center justify-center border border-black w-56 h-56 bg-white ${
                hideCode ? "hidden" : ""
              }`}
            >
              <h5 className="shrink text-3xl text-black py-4">
                <QRCodeSVG size={200} value={link} />
              </h5>
            </div>
            {hideCode && (
              <h5
                className="absolute w-7 h-7 text-center text-sm text-white border-2 font-bold border-white p-1 rounded-full bottom-4 start-4 end-0 cursor-pointer"
                onClick={() => {
                  setHideCode(false);
                }}
              >
                ^
              </h5>
            )}
            <div className={`${hideCode ? "ms-64" : "ms-4"}`}>
              {/*<Tv></Tv>*/}
              <img alt="tv" className="h-72" src={tv}></img>
            </div>
          </div>
        </div>
      )}
      <div className="w-screen h-screen desktop:hidden flex justify-center items-center">
        <h2 className="w-full text-red text-center p-2 xs:text-lg desktop:text-3xl text-2xl font-Inter font-bold py-2 z-50">
          {t("lobby.bigger_screen")}
        </h2>
      </div>
    </div>
  );
}
