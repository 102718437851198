import Socket from "socket.io-client";
import React from "react";

export let socket = Socket(process.env.REACT_APP_API_SOCKET_URL as string, {
    transports: ['websocket']
})

export const connect = (url: string) => {
    socket = Socket(url)
}
export const SocketContext = React.createContext(socket);