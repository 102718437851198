import React, { Suspense, useEffect } from "react";
import { SignForm } from "../components/Authentication/SignForm";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export function Signin() {
  const navigate = useNavigate();
  //const dispatch = useDispatch();
  const authenticated = useSelector(
    (state: RootState) => state.user.authenticated
  );
  //const user = useSelector((state: RootState) => state.user.user)
  const hasPropaganda = useSelector(
    (state: RootState) => state.user.hasPropaganda
  );

  useEffect(() => {
    if (authenticated && hasPropaganda) 
      navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Suspense>
      <Helmet>
        <title>Propaganda: Purshase Propaganda</title>
        <meta name="description" content="Sign in page."></meta>
      </Helmet>
      <div className="w-screen h-screen web:bg-orange phone:bg-red flex flex-col justify-center">
        <div className="w-[100%] h-[70%] mb-6 overflow-hidden">
        <SignForm next={()=>{navigate("/")}} />
        </div>
      </div>
    </Suspense>
  );
}
