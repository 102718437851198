import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../context/socket";
import propagandaPlayer from "../../assets/propaganda-player.svg";

export function Respond() {
  const { t } = useTranslation();
  const [hide, setHide] = useState(false);
  const responses = useSelector((state: RootState) => state.player.responses);
  const room = useSelector((state: RootState) => state.player.code);
  const name = useSelector((state: RootState) => state.player.playerName);
  const lastAnswer = useSelector((state: RootState) => state.player.lastAnswer);
  const socket = useContext(SocketContext);
  const [selected, setSelected] = useState(-1);
  const [h, setH] = useState({});

  useEffect(() => {
    if (window.innerWidth < 1024) setH({ height: window.innerHeight });
  }, []);

  const sendAnswer = (response: string, index: number) => {
    setSelected(index);
    socket.emit("MY_RESPONSE", {
      roomId: room,
      response: response,
      name: name,
    });
    setHide(true);
  };
  return (
    <div
      id="resp"
      className=" w-screen h-screen bg-question-background bg-cover z-0 overflow-hidden "
      style={h}
    >
      {hide && (
        <div className="w-screen h-screen items-center flex flex-col" style={h}>
          <img
            alt="propaganda logo"
            className="shrink z-30 xs:mt-8 mt-16 desktop:h-44"
            src={propagandaPlayer}
          ></img>
        </div>
      )}
      {!hide && (
        <div
          className="w-screen h-screen items-center flex flex-col overflow-y-auto  z-30"
          style={h}
        >
          <h3 className="text-dark-blue xs:text-lg text-3xl font-bold desktop:text-7xl font-Cairo z-30 mt-4 mx-8 desktop:mx-24 text-center">
            {t("respond.choose")}
          </h3>
          {responses.length > 5 && (
            <div className="w-[90%] mt-8 grid grid-cols-2 gap-3">
              {responses
                .filter((item) => lastAnswer !== item)
                .map((response, index) => {
                  return (
                    <button
                      key={index}
                      className="w-[90%] xs:h-24 h-36 text-2xl font-bold phone:mb-4 bg-orange px-2  border-2 border-white rounded-xl text-dark-blue"
                      disabled={selected === index}
                      onClick={() => sendAnswer(response, index)}
                    >
                      {response}
                    </button>
                  );
                })}
            </div>
          )}
          {responses.length < 6 && (
            <div className="w-[80%] h-[80%] mt-8 grid gap-3 content-start overflow-y-auto">
              {responses
                .filter((item) => lastAnswer !== item)
                .map((response, index) => {
                  return (
                    <button
                      key={index}
                      className="w-full xs:h-24 h-24 xs:text-2xl text-4xl phone:mb-4 font-semibold font-Cairo bg-orange px-2  border-2 border-white rounded-xl text-dark-blue disabled:border-sky-600 disabled:shadow-lg"
                      disabled={selected === index}
                      onClick={() => sendAnswer(response, index)}
                    >
                      {" "}
                      {response}
                    </button>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
