import { useTranslation } from 'react-i18next';
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import propagandaPlayer from '../../assets/propaganda-player.svg';
import { useEffect, useState } from 'react';


export function Round() {
    const { t } = useTranslation();
    const round = useSelector((state: RootState) => state.player.roundNumber)
    const [h, setH] = useState({})

    useEffect(() => {
        if (window.innerWidth < 1024)
            setH({ height: window.innerHeight })
    }, [])
    return (
        <div className=' w-screen h-screen bg-orange z-0  overflow-hidden' id='round' style={h}>
            {round === 1 &&
                <div className='w-screen h-screen items-center flex flex-col' style={h}>
                    <img alt="logo propaganda" className='shrink z-30 xs:mt-8 mt-16 desktop:h-44' src={propagandaPlayer}></img>
                    <div className='h-[40%] w-screen bg-cover bg-center mt-10 bg-no-repeat z-30 bg-round-one'></div>
                    <h3 className='xs:w-[40%] w-[60%] text-darker-blue xs:text-5xl font-thin desktop:text-9xl text-7xl text-center font-Inter z-30 xs:mt-10 mt-20 phone:mt-4 desktop:mt-16'>{t("round.one")}</h3>

                </div>}
            {round === 2 &&
                <div className='w-screen h-screen items-center flex flex-col' style={h}>
                    <img alt="logo propaganda" className='shrink z-30 xs:mt-8 mt-16 desktop:h-44' src={propagandaPlayer}></img>
                    <div className='h-[40%] w-screen bg-cover bg-center mt-10 bg-no-repeat z-30 bg-round-two'></div>
                    <h3 className='xs:w-[40%] w-[60%] text-darker-blue xs:text-5xl font-thin desktop:text-9xl text-7xl text-center font-Inter z-30 xs:mt-10 mt-20 phone:mt-4 desktop:mt-16'>{t("round.two")}</h3>

                </div>}
            {round === 3 &&
                <div className='w-screen h-screen items-center flex flex-col' style={h}>
                    <img alt="logo propaganda" className='shrink z-30 xs:mt-8 mt-16 desktop:h-44' src={propagandaPlayer}></img>
                    <div className='h-[40%] w-screen bg-cover bg-center mt-10 bg-no-repeat z-30 bg-laser-background'></div>
                    <h3 className='xs:w-[40%] w-[60%] text-darker-blue xs:text-5xl font-thin desktop:text-9xl text-7xl text-center font-Inter z-30 phone:mt-4 desktop:mt-16'>{t("round.three")}</h3>

                </div>}
        </div >

    )
}