import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../context/socket";
import {
  setCode,
  setCurrentPage,
  setPlayersNumber,
} from "../../store/slices/PlayerSlice";
import { Helmet } from "react-helmet";

export function Over() {
  const { t } = useTranslation();
  const room = useSelector((state: RootState) => state.player.code);
  const name = useSelector((state: RootState) => state.player.playerName);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [h, setH] = useState({});

  useEffect(() => {
    if (window.innerWidth < 1024) setH({ height: window.innerHeight });
  }, []);

  const sendNext = (response: string, id: string, playerName: string) => {
    socket.emit(response, { roomId: room, name: name });
    switch (response) {
      case "NEW_GAME":
        dispatch(setCurrentPage("waiting"));
        dispatch(setPlayersNumber(2));
        break;
      case "PLAY_AGAIN":
        dispatch(setCurrentPage("waiting"));
        break;
      case "EXIT_GAME":
        dispatch(setCurrentPage("join"));
        socket.emit("LEAVE_ROOM", { roomId: id, name: playerName });
        dispatch(setCode(""));
        break;
    }
  };
  return (
    <div
      className="h-screen flex flex-col justify-evenly items-center w-screen bg-over bg-cover bg-right bg-repeat-x overflow-hidden"
      style={h}
    >
      <Helmet>
        <title>Propaganda: Game Ended</title>
        <meta name="description" content="Game ended screen."></meta>
      </Helmet>
      <h3 className="text-6xl text-dark-blue text-center font-bold font-Cairo">
        {t("results.play_again")}
      </h3>
      <button
        className="w-[80%] h-14 font-Cairo text-dark-blue text-2xl bg-orange border-2 border-white rounded-lg"
        onClick={() => sendNext("NEW_GAME", room, name)}
      >
        {t("results.yes_diff")}
      </button>
      <button
        className="w-[80%] h-14 font-Cairo text-dark-blue text-2xl bg-orange border-2 border-white rounded-lg"
        onClick={() => sendNext("PLAY_AGAIN", room, name)}
      >
        {t("results.yes_same")}
      </button>
      <button
        className="w-[80%] h-14 font-Cairo text-dark-blue text-3xl bg-light_green border-2 border-white rounded-lg"
        onClick={() => sendNext("EXIT_GAME", room, name)}
      >
        {t("results.exit")}
      </button>
    </div>
  );
}
