import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../context/socket";
import propagandaPlayer from "../../assets/propaganda-player.svg";
import vipImg from "../../assets/vip.svg";
import hoursglass from "../../assets/Hoursglass.svg";

export function Waiting() {
  const { t } = useTranslation();
  const code = useSelector((state: RootState) => state.player.code);
  const vip = useSelector((state: RootState) => state.player.vip);
  const name = useSelector((state: RootState) => state.player.playerName);
  const gameState = useSelector((state: RootState) => state.player.gameState);
  const playersNumber = useSelector(
    (state: RootState) => state.player.playersNumber
  );
  const [hide, setHide] = useState(false);
  const socket = useContext(SocketContext);
  const playerImageAlt = "player image";
  const hourglassImageAlt = "hourglass";
  const [h, setH] = useState({});

  useEffect(() => {
    if (window.innerWidth < 1024) setH({ height: window.innerHeight });
  }, []);

  const startGame = () => {
    setHide(true);
    socket.emit("QUESTIONS", { roomId: code, name: name });
  };

  return (
    <div
      id="waiting"
      className=" w-screen h-screen bg-join-background bg-cover z-0 overflow-hidden"
      style={h}
    >
      <div
        className="absolute w-screen h-screen bg-orange opacity-40 z-10"
        style={h}
      />
      {vip && gameState === "lobby" && (
        <div className="w-screen h-screen items-center flex flex-col" style={h}>
          <img
            alt={playerImageAlt}
            className="shrink z-30 xs:mt-8 mt-16 desktop:h-44"
            src={propagandaPlayer}
          ></img>
          <img
            alt={hourglassImageAlt}
            className="shrink z-30 xs:mt-8 mt-24 desktop:mt-44 desktop:h-40"
            src={vipImg}
          ></img>
          <h3 className="xs:w-[40%] w-[60%] text-darker-blue desktop:mt-24 xs:text-3xl desktop:text-8xl text-5xl text-center font-Inter z-30 xs:mt-10 mt-20">
            {t("join.ready")}
          </h3>
          {!hide && (
            <button
              className="h-28 w-28 desktop:h-56 desktop:w-56 desktop:text-5xl desktop:mt-24 bg-btn-red text-darker-blue text-3xl font-Cairo rounded-full mt-8 border-2 border-dark-blue z-30 disabled:opacity-50"
              disabled={playersNumber < 2}
              onClick={startGame}
            >
              {t("join.start")}
            </button>
          )}
        </div>
      )}
      {!vip && (
        <div className="w-screen h-screen items-center flex flex-col" style={h}>
          <img
            alt={playerImageAlt}
            className="shrink z-30 xs:mt-8 mt-16 desktop:h-44"
            src={propagandaPlayer}
          ></img>
          <img
            alt={hourglassImageAlt}
            className="shrink z-30 xs:mt-8 mt-24 desktop:mt-44 desktop:h-40"
            src={hoursglass}
          ></img>
          <h3 className="xs:w-[40%] w-[60%] text-darker-blue desktop:mt-24 xs:text-3xl desktop:text-8xl text-5xl text-center font-Inter z-30 xs:mt-10 mt-20">
            {t("join.about_to_start")}
          </h3>
        </div>
      )}
    </div>
  );
}
