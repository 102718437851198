import { useTranslation } from 'react-i18next';
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { Suspense, useContext, useEffect } from 'react';
import { setQuestionPage, SetShowRound } from "../../store/slices/CycleQuestionSlice";
import { setQuestionNumber, setRoundNumber } from "../../store/slices/MainScreenSlice";
import CountUp from 'react-countup';
import { PlayerContext } from '../../context/howler';


export function Results() {
	const { t } = useTranslation();
	const questionNumber = useSelector((state: RootState) => state.mainScreen.questionNumber)
	const questions = useSelector((state: RootState) => state.mainScreen.questions)
	const players = useSelector((state: RootState) => state.mainScreen.players)
	const roundNumber = useSelector((state: RootState) => state.mainScreen.roundNumber)
	const firsRoundLimit = useSelector((state: RootState) => state.mainScreen.firstRound)
	const dispatch = useDispatch();
	const howler = useContext(PlayerContext)

	useEffect(() => {
		let limit = questionNumber + 1;
		switch (roundNumber) {
			case 1:
				setTimeout(() => {
					if (limit === firsRoundLimit) {
						dispatch(setRoundNumber(2))
						dispatch(SetShowRound(true))
						dispatch(setQuestionNumber(limit))
					}
					else {
						dispatch(setQuestionNumber(limit))
					}
					dispatch(setQuestionPage("category"))
					howler.stopBg();
				}, 6000);
				break
			case 2:
				setTimeout(() => {
					if (limit === (questions.length - 1)) {
						dispatch(setRoundNumber(3))
						dispatch(SetShowRound(true))
						dispatch(setQuestionNumber(limit))
					}
					else {
						dispatch(setQuestionNumber(limit))
					}
					dispatch(setQuestionPage("category"))
					howler.stopBg();
				}, 6000);
				break
			case 3:
				dispatch(setQuestionPage("FinalResult"))
				break
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	return (
		<Suspense>
			<div className='flex w-screen h-screen'>
				<h3 className='absolute pr-16 pt-8 text-7xl text-dark-blue' >{t('results.results')}</h3>
				<div className=' grow flex flex-col w-[40%] items-center justify-center bg-light-blue'>
					{
						players.length > 0 && players.map((player, index) => {
							return (
								<div key={index} className='flex flex-row justify-between w-[70%] border-b-2 pb-3 border-dark-blue'>
									<h3 className='text-5xl text-dark-blue font-Cairo'>{player.name}</h3>
									<CountUp className='text-5xl text-dark-blue font-Algerian' end={player.score} duration={(4 / players.length) * (players.length - 1)}></CountUp>
								</div>
							)
						})
					}
				</div>
				<div className='w-[60%] bg-accountant bg-cover bg-center bg-no-repeat'>

				</div>


			</div>
		</Suspense>
	)
}