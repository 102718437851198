import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dollars from "../../assets/dollars.svg";
import apple from "../../assets/ApplePay.svg";
import mada from "../../assets/madaBlack.svg";
import card from "../../assets/card.svg";
import dollarsRev from "../../assets/dollarsRev.svg";
import "./payment.css";
import { PaymentDiv } from "./PaymentDiv";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";

declare global {
  interface Window {
    wpwl: {
      unload?: () => void;
    };
  }
}

export function Payment(props: { next: any }) {
  const { t } = useTranslation();
  const [show, setShow] = useState("card");
  const [showMobile, setShowMobile] = useState("card");
  const [discount, setDiscount] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [validCode, setValidCode] = useState("");
  const [promoCodeErr, setPromoCodeErr] = useState("");
  const user = useSelector(
    (state: RootState) => state.user.user
  );
  const navigate = useNavigate();

  async function validatePromoCode(code: string) {
    axios
      .post(`${process.env.REACT_APP_API_URL}coupon/verify`, {
        code: code,
        email: user.email,
      })
      .then((res) => {
        if (res.data.success) {
          if (res.data.amount === 1 && res.data.granted === true) {
            navigate("/result/granted");
          }
          setPromoCodeErr("");
          setValidCode(promoCode);
          setDiscount(res.data.amount * 100);
        } else {
          setPromoCodeErr("invalid or expired code");
        }
      })
      .catch((err) => {
        setPromoCodeErr("invalid or expired code");
      });
  }

  useEffect(() => {
    localStorage.setItem("randomVal", discount.toString());
  }, [discount]);

  return (
    <Suspense>
      <div className="w-full h-full overflow-y-auto">
        <div className="w-full h-full flex flex-row phone:hidden web:visible">
          <div className="w-[25%] h-full flex items-end justify-end">
            <img
              alt="dollars"
              className="w-full mb-[5%] ml-8"
              src={dollars}
            ></img>
          </div>
          <div className="w-[50%] h-full flex flex-col bg-orange items-end justify-center phone:hidden web:visible">
            <div className="w-full flex flex-col items-center justify-center overflow-hidden">
              <div
                id="payment"
                className="w-full p-2 flex flex-col overflow-auto"
              >
                <div className="py-4 flex flex-row w-full border-b border-red  text-red text-center font-bold font-Cairo uppercase items-center justify-start">
                  <h2 className="w-[25%]">{t("payment.desc")}</h2>
                  <h2 className="w-[25%]">{t("payment.qte")}</h2>
                  <h2 className="w-[25%]">{t("payment.price")}</h2>
                  <h2 className="w-[25%]">{t("payment.amount")}</h2>
                </div>
                <div className="py-4 w-full flex flex-row border-b border-red font-bold text-center font-Cairo uppercase items-center justify-center">
                  <h2 className="w-[25%]">{t("payment.prp")}</h2>
                  <h2 className="w-[25%]">1</h2>
                  <h2 className="w-[25%]">100 SAR</h2>
                  <h2 className="w-[25%]">100 SAR</h2>
                </div>
                <div className="flex flex-row w-full text-center font-bold font-Cairo uppercase items-center justify-end">
                  <div className="py-4 flex flex-row border-b border-red w-[50%]">
                    <h2 className="w-[50%] text-red">{t("payment.sub")}</h2>
                    <h2 className="w-[50%]">100 SAR</h2>
                  </div>
                </div>
                <div className="flex flex-row w-full text-center font-bold font-Cairo uppercase items-center justify-end">
                  <div className="py-4 flex flex-row border-b border-red  w-[50%]">
                    <h2 className="w-[50%] text-red">{t("payment.vat")}</h2>
                    <h2 className="w-[50%]">{(100 - discount) * 0.15} SAR</h2>
                  </div>
                </div>
                <div className="flex flex-row w-full text-center font-bold font-Cairo uppercase items-center justify-end">
                  <div className="py-4 flex flex-row border-b border-red  w-[50%]">
                    <h2 className="w-[50%] text-red">
                      {t("payment.discount")}
                    </h2>
                    <h2 className="w-[50%]">{discount} SAR</h2>
                  </div>
                </div>
                <div className="mb-4 flex flex-row w-full text-center font-bold font-Cairo uppercase items-center justify-end">
                  <div className="py-4 flex flex-row border-b border-red  w-[50%]">
                    <h2 className="w-[50%] text-red">{t("payment.total")}</h2>
                    <h2 className="w-[50%]">
                      {100 - discount + (100 - discount) * 0.15} SAR
                    </h2>
                  </div>
                </div>
                <div className="w-full flex flex-col p-2">
                  <div className="w-full flex flex-row items-center justify-center gap-4">
                    <h3 className="p-2 font-inter font-semibold">
                      {t("payment.code")}
                    </h3>{" "}
                    <input
                      type="text"
                      className="p-2"
                      value={promoCode}
                      onChange={(event) => {
                        setPromoCode(event.target.value.toLocaleUpperCase());
                      }}
                      placeholder={t("payment.promo") || "Promo code"}
                    ></input>
                    <button
                      onClick={() => {
                        validatePromoCode(promoCode);
                      }}
                      className="px-4 bg-light-blue p-2 border-2 font-Inter font-bold border-black shadow-lg"
                    >
                      {t("payment.verify")}
                    </button>
                  </div>
                  {promoCodeErr && (
                    <div className="w-full flex flex-row items-center justify-center p-4 gap-4">
                      <h3 className="font-inter font-semibold text-red">
                        {promoCodeErr}
                      </h3>
                    </div>
                  )}
                </div>
                <div className="w-full flex flex-col items-center justify-center">
                  <div className="w-[80%] flex flex-row items-center justify-items-end pb-6">
                    <div
                      className="relative w-[50%] h-20 flex items-center justify-center py-4 text-white text-center border border-black shadow-xl"
                      style={{
                        transform: show === "mada" ? "translateY(1px)" : "",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShow("mada");
                      }}
                    >
                      {show !== "mada" && (
                        <div className="absolute bg-paymentBg h-full w-full z-10" />
                      )}
                      <img src={mada} alt="applePay" className="z-20 h-full" />
                    </div>
                    <div
                      className="relative w-[50%] h-20 flex items-center justify-center py-4 text-white text-center border border-black shadow-xl"
                      style={{
                        transform: show === "apple" ? "translateY(1px)" : "",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShow("apple");
                      }}
                    >
                      {show !== "apple" && (
                        <div className="absolute bg-paymentBg h-full w-full z-10" />
                      )}
                      <img src={apple} alt="applePay" className="z-20" />
                    </div>
                    <div
                      className="relative w-[50%] h-20 flex items-center justify-center py-4 font-inter text-black text-center text-xl border border-black shadow-xl"
                      style={{
                        transform: show === "card" ? "translateY(1px)" : "",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setShow("card");
                      }}
                    >
                      {show !== "card" && (
                        <div className="absolute bg-paymentBg h-full w-full z-10" />
                      )}
                      <img src={card} alt="applePay" className="z-20 h-full" />
                    </div>
                  </div>
                  {window.innerWidth >= 1024 && (
                    <PaymentDiv method={show} code={validCode} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-[25%] h-full flex items-end justify-start overflow-x-clip">
            <img
              alt="dollars"
              className="w-full mb-[5%] mr-8"
              src={dollarsRev}
            ></img>
          </div>
        </div>

        <div className="w-full h-full bg-red flex flex-col items-center justify-start overflow-y-auto phone:visible  web:hidden">
          <div className="w-[90%] mb-4 flex flex-row items-center justify-center text-xs">
            <div className="min-w-[15%] gap-2 flex flex-col w-full underline text-black text-center font-bold font-Cairo uppercase items-center justify-start">
              <h2 className="text-[14px]">{t("payment.desc")}</h2>
              <h2 className="text-[14px]">{t("payment.qte")}</h2>
              <h2 className="text-[14px]">{t("payment.price")}</h2>
              <h2 className="text-[14px]">{t("payment.amount")}</h2>
            </div>
            <div className="min-w-[15%] border-e border-black gap-2 w-full flex flex-col font-bold text-center font-Cairo uppercase items-center justify-center">
              <h2 className="text-[14px]">{t("payment.prp")}</h2>
              <h2 className="text-[14px]">1</h2>
              <h2 className="text-[14px]">100 SAR</h2>
              <h2 className="text-[14px]">100 SAR</h2>
            </div>
            <div className="min-w-[20%] h-full underline gap-4 w-full flex flex-col font-bold text-center font-Cairo uppercase items-center justify-center">
              <h2 className="text-black text-[14px]">{t("payment.sub")}</h2>
              <h2 className="text-black text-[14px]">{t("payment.vat")}</h2>
              <h2 className="text-black text-[14px]">{t("payment.discount")}</h2>
              <h2 className="text-black text-[14px]">{t("payment.total")}</h2>
            </div>
            <div className="min-w-[20%] h-full items-center justify-between w-full flex flex-col font-bold text-center font-Cairo uppercase">
              <h2 className="text-black text-[14px]">100 SAR</h2>
              <h2 className="text-black text-[14px]">{(100 - discount) * 0.15} SAR</h2>
              <h2 className="text-black text-[14px]">{discount} SAR</h2>
              <h2 className="text-black text-[14px]">{100 - discount + (100 - discount) * 0.15} SAR</h2>
            </div>
          </div>
          <div className="w-full flex flex-row items-center text-[12px] justify-center gap-2">
            <h3 className="p-2 font-inter font-semibold">
              {t("payment.code")}
            </h3>{" "}
            <input
              type="text"
              className="p-2"
              value={promoCode}
              onChange={(event) => {
                setPromoCode(event.target.value);
              }}
              placeholder={t("payment.promo") || "Promo code"}
            ></input>
            <button
              onClick={() => {
                validatePromoCode(promoCode);
              }}
              className="px-4 bg-light-blue p-2 border-2 font-Inter font-bold border-black shadow-lg"
            >
              {t("payment.verify")}
            </button>
          </div>
          <div className="w-[80%] flex flex-row items-center justify-center pb-6">
            <div
              className="relative w-[50%] h-20 flex bg-landing-yellow items-center justify-center py-4 text-white text-center border border-black shadow-xl"
              style={{
                transform: showMobile === "mada" ? "translateY(1px)" : "",
              }}
              onClick={() => {
                setShowMobile("mada");
              }}
            >
              {showMobile !== "mada" && (
                <div className="absolute bg-paymentBg h-full w-full z-10" />
              )}
              <img src={mada} alt="applePay" className="z-20 h-full" />
            </div>
            <div
              className="relative w-[50%] h-20 flex bg-landing-yellow items-center justify-center py-4 text-white text-center border border-black shadow-xl"
              style={{
                transform: showMobile === "apple" ? "translateY(1px)" : "",
              }}
              onClick={() => {
                setShowMobile("apple");
              }}
            >
              {showMobile !== "apple" && (
                <div className="absolute bg-paymentBg h-full w-full z-10" />
              )}
              <img src={apple} alt="applePay" className="z-20" />
            </div>
            <div
              className="relative w-[50%] h-20 flex bg-landing-yellow items-center justify-center py-4 font-inter text-black text-center text-xl border border-black shadow-xl"
              style={{
                transform: showMobile === "card" ? "translateY(1px)" : "",
              }}
              onClick={() => {
                setShowMobile("card");
              }}
            >
              {showMobile !== "card" && (
                <div className="absolute bg-paymentBg h-full w-full z-10" />
              )}
              <img src={card} alt="applePay" className="z-20 h-full" />
            </div>
          </div>
          {window.innerWidth < 1024 && (
            <PaymentDiv method={showMobile} code={validCode} />
          )}
        </div>
      </div>
    </Suspense>
  );
}
