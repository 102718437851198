import { useTranslation } from 'react-i18next';
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { Suspense, useContext, useEffect, useState } from 'react';
import { SetShowRound } from '../../store/slices/CycleQuestionSlice';
import { PlayerContext } from '../../context/howler';
import { motion } from 'framer-motion';
import { HowlerPlayer } from '../../context/HowlerPlayer';

export function Round() {
	const { t } = useTranslation();
	const round = useSelector((state: RootState) => state.mainScreen.roundNumber)
	const dispatch = useDispatch();
	const howler = useContext(PlayerContext)
	const [bgLoaded, setBgLoaded] = useState(true);
	useEffect(() => {
		const img = new Image();

		if (round === 1 || round === 2) {
				img.src = window.location.origin + `/images/bgs/roundOne.svg`;
				img.onload = () => {
						loaded();
						howler.stop()
						howler.playGameTrack('round1_&_2.mp3', false, HowlerPlayer.musicVolume)
				};
		}
		else {
				img.src = window.location.origin + `/images/bgs/journal.svg`;
				img.onload = () => {
						loaded();
						howler.stop()
						howler.playGameTrack('final_round.mp3', false, HowlerPlayer.musicVolume);
				}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loaded = () => {
		setBgLoaded(true)
		setTimeout(() => {
			dispatch(SetShowRound(false))
		}, 12000);
	}


	return (
		<Suspense>
			{!bgLoaded && <div className='flex w-screen h-screen bg-orange' >

			</div>
			}
			{bgLoaded && round === 1 && <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 1 }} className='flex w-screen h-screen bg-darker-blue'>

				<div className='grow flex flex-col w-[45%] gap-[20%] items-center justify-end bg-darker-blue'>
					<motion.h3 animate={{ scale: 1.1 }} initial={{ scale: 0.95 }} transition={{ repeat: Infinity, repeatType: "reverse", duration: 1 }} className='text-9xl text-orange font-Cairo' >{t('round.one')}</motion.h3>
					<div className='w-[95%] flex flex-row justify-center gap-[10%] mb-[15%]'>
						<motion.div className='relative flex flex-col gap-6 items-center bg-btn-red p-10 rounded-xl  border border-white' animate={{ scale: 1, rotate: 360, opacity: 1 }} initial={{ scale: 0.2, opacity: 0, rotate: 270 }} transition={{ delay: 1, duration: 2 }}>
							<h1 className='absolute text-4xl top-[-15px] left-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl top-[-15px] right-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl bottom-1 left-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl bottom-1 right-3 font-bold text-white'>.</h1>
							<h3 className='text-4xl text-orange font-Cairo' >500 نقطة </h3>
							<h3 className='text-4xl text-black font-Cairo' >  اذا احد اختار كذبتك</h3>
						</motion.div>
						<motion.div className='relative flex flex-col gap-6 items-center bg-green-600   p-10 rounded-xl  border border-white' animate={{ scale: 1, rotate: 0, opacity: 1 }} initial={{ scale: 0.2, opacity: 0, rotate: 90 }} transition={{ delay: 1, duration: 2 }}>
							<h3 className='text-4xl text-orange font-Cairo' >1000 نقطة </h3>
							<h3 className='text-4xl text-white font-Cairo' >اذا اخترت الحقيقة</h3>
							<h1 className='absolute text-4xl text-white top-[-15px] left-3 font-bold'>.</h1>
							<h1 className='absolute  text-4xl text-white top-[-15px] right-3 font-bold'>.</h1>
							<h1 className='absolute text-4xl text-white bottom-1 left-3 font-bold'>.</h1>
							<h1 className='absolute text-4xl text-white bottom-1 right-3 font-bold'>.</h1>
						</motion.div>
					</div>
				</div>
				<div className='w-[55%] bg-round-one bg-cover' />
			</motion.div>}
			{bgLoaded && round === 2 && <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 1.5 }} className='flex w-screen h-screen bg-darker-blue'>

				<div className='grow flex flex-col w-[45%] gap-[20%] items-center justify-end bg-darker-blue'>
					<motion.h3 animate={{ scale: 1.1 }} initial={{ scale: 0.95 }} transition={{ repeat: Infinity, repeatType: "reverse", duration: 1 }} className='text-9xl text-orange font-Cairo' >{t('round.two')}</motion.h3>
					<div className='w-[95%] flex flex-row justify-center gap-[10%] mb-[15%]'>
						<motion.div className='relative flex flex-col gap-6 items-center bg-btn-red p-10 rounded-xl  border border-white' animate={{ scale: 1, rotate: 360, opacity: 1 }} initial={{ scale: 0.2, opacity: 0, rotate: 270 }} transition={{ delay: 1, duration: 2 }}>
							<h1 className='absolute text-4xl top-[-15px] left-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl top-[-15px] right-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl bottom-1 left-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl bottom-1 right-3 font-bold text-white'>.</h1>
							<h3 className='text-4xl text-orange font-Cairo' >1000 نقطة </h3>
							<h3 className='text-4xl text-black font-Cairo' >  اذا احد اختار كذبتك</h3>
						</motion.div>
						<motion.div className='relative flex flex-col gap-6 items-center bg-green-600   p-10 rounded-xl  border border-white' animate={{ scale: 1, rotate: 0, opacity: 1 }} initial={{ scale: 0.2, opacity: 0, rotate: 90 }} transition={{ delay: 1, duration: 2 }}>
							<h3 className='text-4xl text-orange font-Cairo' >1000 نقطة </h3>
							<h3 className='text-4xl text-white font-Cairo' >اذا اخترت الحقيقة</h3>
							<h1 className='absolute text-4xl text-white top-[-15px] left-3 font-bold'>.</h1>
							<h1 className='absolute  text-4xl text-white top-[-15px] right-3 font-bold'>.</h1>
							<h1 className='absolute text-4xl text-white bottom-1 left-3 font-bold'>.</h1>
							<h1 className='absolute text-4xl text-white bottom-1 right-3 font-bold'>.</h1>
						</motion.div>
					</div>

				</div>
				<div className='w-[55%] bg-round-two bg-cover' />

			</motion.div>}
			{bgLoaded && round === 3 && <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 1.5 }} className='flex w-screen h-screen bg-darker-blue'>

				<div className='grow flex flex-col w-[45%] gap-[20%] items-center justify-end bg-darker-blue'>

					<motion.h3 animate={{ scale: 1.1 }} initial={{ scale: 0.95 }} transition={{ repeat: Infinity, repeatType: "reverse", duration: 1 }} className='text-9xl text-orange font-Cairo' >{t('round.three')}</motion.h3>
					<div className='w-[95%] flex flex-row justify-center gap-[10%] mb-[15%]'>
						<motion.div className='relative flex flex-col gap-6 items-center bg-btn-red p-10 rounded-xl  border border-white' animate={{ scale: 1, rotate: 360, opacity: 1 }} initial={{ scale: 0.2, opacity: 0, rotate: 270 }} transition={{ delay: 1, duration: 2 }}>
							<h1 className='absolute text-4xl top-[-15px] left-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl top-[-15px] right-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl bottom-1 left-3 font-bold text-white'>.</h1>
							<h1 className='absolute text-4xl bottom-1 right-3 font-bold text-white'>.</h1>
							<h3 className='text-4xl text-orange font-Cairo' >3000 نقطة </h3>
							<h3 className='text-4xl text-black font-Cairo' >  اذا احد اختار كذبتك</h3>
						</motion.div>
						<motion.div className='relative flex flex-col gap-6 items-center bg-green-600   p-10 rounded-xl  border border-white' animate={{ scale: 1, rotate: 0, opacity: 1 }} initial={{ scale: 0.2, opacity: 0, rotate: 90 }} transition={{ delay: 1, duration: 2 }}>
							<h3 className='text-4xl text-orange font-Cairo' >1000 نقطة </h3>
							<h3 className='text-4xl text-white font-Cairo' >اذا اخترت الحقيقة</h3>
							<h1 className='absolute text-4xl text-white top-[-15px] left-3 font-bold'>.</h1>
							<h1 className='absolute  text-4xl text-white top-[-15px] right-3 font-bold'>.</h1>
							<h1 className='absolute text-4xl text-white bottom-1 left-3 font-bold'>.</h1>
							<h1 className='absolute text-4xl text-white bottom-1 right-3 font-bold'>.</h1>
						</motion.div>
					</div>
				</div>
				<div className='w-[55%] bg-laser-background bg-cover' />
			</motion.div>}

		</Suspense>
	)
}