import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IResults } from "../../store/slices/MainScreenSlice";
import { RootState } from "../../store/store";
import dollar from "../../assets/dollar.svg";
import { PlayerContext } from "../../context/howler";

export function ButtonResult(props: { result: IResults | null; goNext: any }) {
  const round = useSelector((state: RootState) => state.mainScreen.roundNumber);
  const currentQuestion = useSelector(
    (state: RootState) => state.mainScreen.currentQuestion
  );
  const howler = useContext(PlayerContext);
  const [pointsLie, setPointsLie] = useState(500);
  const liesSFX = ["wrong_answer.mp3", "afa.mp3"];

  const TagPlayer = (props: { player: string; css: string; delay: number }) => {
    return (
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1, rotate: -10 }}
        transition={{ duration: 1, delay: props.delay }}
        className={
          "text-white text-xl text-center font-thin font-Inter rounded-3xl border-[2px] border-black bg-slate-500 z-50 " +
          props.css
        }
      >
        {props.player}
      </motion.div>
    );
  };
  useEffect(() => {
    if (props.result) {
      if (props.result.response.truth)
        howler.playTruth(currentQuestion.answerSFX);
      //howler.playGameTrack("truth_sound_FX.mp3", false, 1)
      else howler.playGameTrack(liesSFX[Math.round(Math.random())], false, 1);
      if (props.result) {
        let points = 1000;
        let pointsLiex = 500;
        switch (round) {
          case 2:
            pointsLiex = 1000;
            break;
          case 3:
            pointsLiex = 3000;
            break;
        }
        let pointsToAdd = getPoints(pointsLiex);
        setTimeout(() => {
          if (props?.result?.response?.truth) {
            props.goNext(
              props.result.response.truth,
              props.result.response.name,
              points,
              true,
              props.result.response.lie
            );
          } else {
            if (!props?.result?.response.our) {
              props.goNext(
                props?.result?.response.truth,
                props?.result?.response.name,
                pointsToAdd,
                true,
                props?.result?.response.lie
              );
            } else {
              props.goNext(
                props.result.response.truth,
                props.result.response.name,
                0,
                true,
                props.result.response.lie
              );
            }
          }
        }, 6000);
      } else {
        props.goNext(null, null, null, false, null);
      }
    } else {
      props.goNext(null, null, null, false, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPoints = (reward: number) => {
    if (props.result) {
      setPointsLie((props.result.players?.length || 0) * reward);
      return (props.result.players?.length || 0) * reward;
    }
  };
  return (
    <div className=" flex flex-col justify-center items-center w-[100%] h-[100%] z-40">
      {props.result && !props.result.response.truth && (
        <motion.button
          animate={{ scale: 1 }}
          initial={{ scale: 0 }}
          transition={{ type: "tween", duration: 1 }}
          className="relative text-9xl w-[60%] h-[60%] text-dark-blue font-Cairo bg-beige rounded-xl border-2 border-orange"
        >
          {props.result.response.lie}
          {props.result.response.our && (
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ delay: 4 }}
              className="absolute w-[100%] bottom-4 text-red-600 font-thin text-6xl text-center font-Cairo z-40"
            >
              كذبتنا
            </motion.h3>
          )}
          {!props.result.response.our && (
            <motion.h3
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ delay: 4 }}
              className="absolute w-[100%] bottom-4 text-red-600 font-thin text-6xl text-center font-Cairo z-40"
            >
              {" "}
              كذبة {props.result.response.name}
            </motion.h3>
          )}
          <div className="absolute bottom-[-30px] w-[100%] flex flex-row-reverse justify-between z-40">
            {props.result.players &&
              props.result.players?.length > 0 &&
              props.result.players.map((player, index) => {
                return (
                  <TagPlayer
                    key={index}
                    delay={0.5 * (index + 1)}
                    player={player}
                    css={" h-10 px-8 "}
                  ></TagPlayer>
                );
              })}
          </div>
          {!props.result.response.our && (
            <motion.div
              className="absolute right-16 top-16 flex flex-row z-40"
              initial={{ scale: 0 }}
              animate={{ scale: 1, rotate: 20 }}
              transition={{ duration: 1, delay: 4.5 }}
            >
              <img alt="dollar" className="shrink h-16 w-16" src={dollar}></img>
              <h3 className="text-red-600 text-6xl font-thin font-Cairo mr-[-16px]">
                {pointsLie}+
              </h3>
            </motion.div>
          )}
          {props.result.response.our && (
            <motion.div
              className="absolute right-16 top-16 flex flex-row z-40"
              initial={{ scale: 0 }}
              animate={{ scale: 1, rotate: 20 }}
              transition={{ duration: 1, delay: 4.5 }}
            >
              <img alt="dollar" className="shrink h-16 w-16" src={dollar}></img>
              <h3 className="text-red-600 text-6xl font-thin font-Cairo mr-[-16px]">
                {" "}
                500-
              </h3>
            </motion.div>
          )}
        </motion.button>
      )}
      {props.result && props.result.response.truth && (
        <motion.div
          className="relative flex flex-col justify-center items-center  w-[100%] h-[70%] bg-truth bg-cover bg-bottom z-40"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <h3 className="shrink text-dark-blue text-7xl font-semibold font-Cairo z-40">
            {props.result.response.lie}
          </h3>
          <h3 className="shrink text-dark-blue text-9xl font-thin font-Cairo z-40">
            الصدق
          </h3>
          <div className="absolute  bottom-[-30px] w-[100%] flex flex-row-reverse justify-between z-40">
            {props.result.players &&
              props.result.players?.length > 0 &&
              props.result.players.map((player, index) => {
                return (
                  <TagPlayer
                    key={index}
                    delay={0.5 * (index + 1)}
                    player={player}
                    css={" h-10 px-8 mx-10"}
                  ></TagPlayer>
                );
              })}
          </div>
        </motion.div>
      )}
    </div>
  );
}
